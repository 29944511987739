import { useEffect, useState } from 'react';

import {
  TPositionMetricEntity,
  TTradingAccountEntity,
  TConversionRateInstrumentsFE,
  useMst,
} from '@trader/store';
import {
  EConnectionHub,
  EConnectionSubscription,
  HubConnection,
} from '@trader/services';
import { IMessage } from '@trader/types';
import { IGetExchangeRateInstrumentsBody } from '@trader/api';
import { getAccountTypeForConnection, productId } from '@trader/constants';

import { useStartConnection } from './core';

type TUpdatedPositions = {
  positions: { positionId: string; conversionRate: number }[];
  platformLogin: string;
};

interface IParams {
  hub: EConnectionHub;
  subscriber?: EConnectionSubscription;
  account: TTradingAccountEntity;
  onUpdate: (updatedPositions: TUpdatedPositions) => void;
}

export const usePositionsConversionRate = (params?: IParams) => {
  const store = useMst();

  const { connection, isAppDataLoaded } = useStartConnection(
    params?.hub || EConnectionHub.Positions,
    params?.subscriber || EConnectionSubscription.PositionsConversionRate,
    'quotes'
  );

  const [positionsConversionRate, setPositionsConversionRate] =
    useState<TConversionRateInstrumentsFE>([]);

  const positionSymbols = positionsConversionRate.map(per => per.rateSymbol);

  const handleSubscribe = async (hub: HubConnection) => {
    if (positionsConversionRate.length > 0 && platformLogin && accountType) {
      await hub.send(
        'SubscribeOnQuotes',
        positionSymbols,
        1,
        productId[product],
        platformLogin,
        getAccountTypeForConnection[accountType]
      );
      hub.on('OnQuote', handleMessage);
    }
  };

  const idToken = store.auth.tokens.idToken;
  const isAuth = store.auth.isAuth;
  const activeTradingAccount = params?.account || store.user.tradingAccount;
  const platformLogin = activeTradingAccount?.platformLogin;
  const accountType = activeTradingAccount?.accountType;
  const product = store.user.getAccountProduct();

  const portfolioCurrency = activeTradingAccount?.currency || '';
  const positions =
    params?.account?.positions ||
    store.entities.positionsMetrics.getAll<TPositionMetricEntity>();

  const fetchPositionsExchangeRate = async () => {
    const body: IGetExchangeRateInstrumentsBody[] = [];

    for (const position of positions) {
      const isPortfolioCurrency = position.currency === portfolioCurrency;
      const isSymbolAdded = !!body.find(i => i.symbol === position.symbol);
      const isSymbolRateFetched = !!positionsConversionRate.find(
        ers => ers.positionSymbol === position.symbol
      );

      if (!isPortfolioCurrency && !isSymbolAdded && !isSymbolRateFetched) {
        body.push({
          currencyBase: portfolioCurrency,
          calcCurrency: position.currency,
          symbol: position.symbol,
        });
      }
    }

    if (body.length > 0) {
      const resp =
        await store.entities.instruments.getExchangeRateInstrumentsAsync.run(
          body
        );
      resp && setPositionsConversionRate(prev => [...prev, ...resp]);
    }
  };

  function handleMessage(message: IMessage) {
    const positionExchangeRate = positionsConversionRate.find(
      per => per.rateSymbol === message.s
    );
    const positionSymbolToUpdate = positionExchangeRate?.positionSymbol;
    const isReversed = positionExchangeRate?.isReversed;
    const updatedPositions: TUpdatedPositions['positions'] = [];

    for (const position of positions) {
      if (position.symbol === positionSymbolToUpdate) {
        const conversionRateValue =
          position.side === 'Buy' ? message.b : message.a;
        const conversionRate = isReversed
          ? 1 / conversionRateValue
          : conversionRateValue;

        if (params?.onUpdate) {
          updatedPositions.push({
            positionId: position.positionId,
            conversionRate,
          });
        } else {
          store.entities.positionsMetrics.update(position.positionId, {
            conversionRate: conversionRate,
          });
        }
      }
    }

    params?.onUpdate &&
      params?.onUpdate({
        positions: updatedPositions,
        platformLogin: activeTradingAccount?.platformLogin || '',
      });
  }

  /**
   * Handle fetch position exchange rates.
   */
  useEffect(() => {
    if (
      isAuth &&
      portfolioCurrency &&
      platformLogin &&
      positions &&
      positions.length > 0
    ) {
      fetchPositionsExchangeRate();
    }
  }, [(positions || []).length, portfolioCurrency, platformLogin]);

  /**
   * Handle subscribe on positions exchange rates.
   */
  useEffect(() => {
    isAppDataLoaded && connection.subscribe(handleSubscribe);

    return () => {
      if (positionsConversionRate.length > 0 && platformLogin && accountType) {
        connection.unsubscribe(async hub => {
          await hub?.send(
            'UnsubscribeFromAllQuotes',
            positionSymbols,
            1,
            productId[product],
            platformLogin,
            getAccountTypeForConnection[accountType]
          );
        });
      }
    };
  }, [positionsConversionRate.length, platformLogin, idToken, isAppDataLoaded]);
};

import { FC } from 'react';
import { useMediaQuery, useTheme } from '@mui/material';

import { MobilePositionsList } from './mobile';
import { DesktopPositionsList } from './desktop';

export interface IPositionsList {
  symbol?: string;
}

export const PositionsList: FC<IPositionsList> = ({ symbol }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return isMobile ? (
    <MobilePositionsList symbol={symbol} />
  ) : (
    <DesktopPositionsList symbol={symbol} />
  );
};

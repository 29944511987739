import { IDataFreeParams } from './types';
import { indexDBName } from './constants';

export const get = <TResponse>({
  key,
  version,
  storeName,
}: IDataFreeParams): Promise<TResponse | undefined> =>
  new Promise(resolve => {
    console.log(
      `Opening Index DB ${indexDBName} to GET data for ${storeName} (v${version})`
    );

    const request = indexedDB.open(indexDBName, version);

    request.onsuccess = () => {
      console.log('Index DB opened successfully.');
      console.log('Available object stores:', request.result.objectStoreNames);

      // Check if the store exists before performing any transaction
      if (!request.result.objectStoreNames.contains(storeName)) {
        console.error(`Store "${storeName}" not found in the DB!`);
        resolve(undefined);
        return;
      }

      console.log(`Starting GET transaction for store: ${storeName}`);
      try {
        const tx = request.result.transaction(storeName, 'readonly');
        const store = tx.objectStore(storeName);
        const res = !key ? store.getAll() : store.get(key);

        res.onsuccess = () => {
          console.log(`Data fetched successfully for ${storeName}`, res.result);
          resolve(res.result as TResponse);
        };

        res.onerror = err => {
          console.error(`Error fetching data from store "${storeName}"`, err);
          resolve(undefined);
        };
      } catch (error) {
        console.error(`Transaction failed for store "${storeName}"`, error);
        resolve(undefined);
      }
    };

    request.onerror = err => {
      console.error('Opening IndexedDB Error while trying to GET data:', err);
      resolve(undefined);
    };
  });

import { FC } from 'react';
import { useMediaQuery, useTheme } from '@mui/material';

import { MobilePendingOrdersList } from './mobile';
import { DesktopPendingOrdersList } from './desktop';

export interface IPendingOrdersList {
  symbol?: string;
}

export const PendingOrdersList: FC<IPendingOrdersList> = ({ symbol }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return isMobile ? (
    <MobilePendingOrdersList symbol={symbol} />
  ) : (
    <DesktopPendingOrdersList symbol={symbol} />
  );
};

import { useEffect } from 'react';

import { useMst } from '@trader/store';
import { EConnectionHub, EConnectionSubscription } from '@trader/services';

import { useStartConnection } from './core';

interface IAuth {
  sessionId: string;
}

/**
 * Custom hook that handles authentication logic.
 * It subscribes to the authentication hub and logs out the user if the session ID changes.
 */
export const useAuth = () => {
  const store = useMst();
  const { connection } = useStartConnection(
    EConnectionHub.Auth,
    EConnectionSubscription.Auth,
    'auth'
  );

  const idToken = store.auth.tokens.idToken;

  const handleMessage = (message: IAuth) => {
    if (message.sessionId === store.auth.tokens.sessionId) {
      store.auth.logOut();
    }
  };

  useEffect(() => {
    if (store.auth.isAuth) {
      connection.subscribe(hub => {
        hub.on('signOut', handleMessage);
      });
    }
  }, [store.auth.isAuth, idToken]);
};

import React, { useMemo } from 'react';
import { observer } from 'mobx-react-lite';

import {
  Icon,
  Tooltip,
  ScrollableTable,
  ObservableTableColumn,
  IScrollableTableColumn,
} from '@trader/components';
import { TPositionMetricEntity, useMst } from '@trader/store';
import { calcPositionPl, formatTableRowValueByPipSize } from '@trader/utils';
import { useExtendItemWidthDependsLng } from '@trader/hooks';
import { useI18next } from '@trader/services';

import {
  PurchaseType,
  SymbolWithIcon,
  ChangePercentage,
  ProfitValue,
} from '../scrollingList';
import { CloseAllPositions } from './components/closeAllPositions';
import { usePositionsListMetrics } from './hooks/usePositionsListMetrics';
import { IPositionsList } from './index';
import * as Styled from './desktop.styled';
import { PositionActions } from './components/positionActions';

export const DesktopPositionsList: React.FC<IPositionsList> = observer(
  ({ symbol }) => {
    const store = useMst();
    const { translate } = useI18next();
    const { getExtendedWidth } = useExtendItemWidthDependsLng();
    const { positionsMetrics } = usePositionsListMetrics(symbol);

    const renderPlHeader = () => (
      <Styled.PlLabel>
        {translate('COMMON.LABELS.P_L')}
        <Tooltip title={translate('TOOLTIPS.PL_WITHOUT_SWAP')}>
          <Icon iconType='info' />
        </Tooltip>
      </Styled.PlLabel>
    );

    const renderSymbol = (
      s: string,
      url: string,
      strategy: TPositionMetricEntity['strategy'],
      id: string
    ) => {
      const closeTime = store.pages.muliBands.getCloseTime(id, strategy);

      if (closeTime) {
        return (
          <Styled.SymbolWithTimer>
            <SymbolWithIcon symbol={s} iconUrl={url} />
            <Tooltip title={`Close time: ${closeTime}`}>
              <Icon iconType='timer' />
            </Tooltip>
          </Styled.SymbolWithTimer>
        );
      }

      return <SymbolWithIcon symbol={s} iconUrl={url} />;
    };

    const columns: IScrollableTableColumn<TPositionMetricEntity>[] = useMemo(
      () => [
        {
          id: 'symbol',
          header: translate('COMMON.LABELS.SYMBOL'),
          minWidth: 115,
          cellStyle: { paddingLeft: '15px' },
          headerStyle: { textAlign: 'left', paddingLeft: '20px' },
          render: row =>
            renderSymbol(row.symbol, row.iconUrl, row.strategy, row.positionId),
        },
        {
          id: 'positionId',
          header: translate('COMMON.LABELS.POSITION_ID'),
          minWidth: 100,
        },
        {
          id: 'side',
          header: translate('COMMON.LABELS.TYPE'),
          minWidth: getExtendedWidth('60', '2', ['nl']),
          render: row => <PurchaseType value={row.side as string} />,
        },
        {
          id: 'quantity',
          header: translate('COMMON.LABELS.VOLUME'),
          minWidth: 90,
        },
        {
          id: 'pl',
          header: renderPlHeader(),
          minWidth: 100,
          render: row => (
            <ObservableTableColumn
              row={row}
              render={r => <ProfitValue value={calcPositionPl(r).toFixed(2)} />}
            />
          ),
        },
        {
          id: 'netPl',
          header: translate('COMMON.LABELS.NET_PL'),
          minWidth: 100,
          render: row => (
            <ObservableTableColumn
              row={row}
              render={r => (
                <ProfitValue value={(calcPositionPl(r) + r.swap).toFixed(2)} />
              )}
            />
          ),
        },
        {
          id: 'openPrice',
          header: translate('COMMON.LABELS.OPEN_PRICE'),
          minWidth: 120,
          render: row =>
            formatTableRowValueByPipSize(row.openPrice, row.pipSize),
        },
        {
          id: 'currentPrice',
          header: translate('COMMON.LABELS.CURRENT_PRICE'),
          minWidth: 120,
          render: row => (
            <ObservableTableColumn
              row={row}
              render={r => formatTableRowValueByPipSize(r.price, r.pipSize)}
            />
          ),
        },
        { id: 'swap', header: translate('COMMON.LABELS.SWAP'), minWidth: 80 },
        {
          id: 'value',
          header: translate('COMMON.LABELS.VALUE'),
          minWidth: 120,
          render: row => (
            <ObservableTableColumn
              row={row}
              render={r => Number((r.price * r.quantity).toFixed(1))}
            />
          ),
        },
        {
          id: 'stopLoss',
          header: translate('COMMON.LABELS.SL'),
          minWidth: 90,
          render: row =>
            formatTableRowValueByPipSize(row.stopLoss.stopPrice, row.pipSize),
        },
        {
          id: 'takeProfit',
          header: translate('COMMON.LABELS.TP'),
          minWidth: 90,
          render: row =>
            formatTableRowValueByPipSize(
              row.takeProfit.limitPrice,
              row.pipSize
            ),
        },
        {
          id: 'change',
          header: translate('COMMON.LABELS.CHANGE'),
          minWidth: 110,
          render: row => (
            <ObservableTableColumn
              row={row}
              render={r => (
                <ChangePercentage
                  entryValue={r.openPrice}
                  exitValue={r.price}
                  side={r.side}
                />
              )}
            />
          ),
        },
        {
          id: 'actions',
          header: positionsMetrics.length ? <CloseAllPositions /> : '',
          minWidth: getExtendedWidth('80', '1.35', ['sk']),
          cellStyle: { paddingLeft: '15px' },
          headerStyle: { textAlign: 'right', paddingRight: '20px' },
          render: row => <PositionActions position={row} />,
        },
      ],
      [positionsMetrics.length]
    );

    return (
      <Styled.Root>
        <ScrollableTable<TPositionMetricEntity>
          data={positionsMetrics}
          columns={columns}
          emptyMessage={translate('COMMON.LABELS.NO_POSITIONS')}
        />
      </Styled.Root>
    );
  }
);

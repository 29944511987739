import styled, { css } from 'styled-components';

import { Button as ButtonComp, Wrapper } from '@trader/components';

export const Root = styled(Wrapper)`
  ${() => css`
    height: 100%;
    flex-direction: column;
  `}
`;

export const Account = styled(Wrapper)`
  ${() => css`
    flex-direction: column;
  `}
`;

export const Badge = styled(Wrapper)<{ $isActive?: boolean }>`
  ${({ theme, $isActive }) => css`
    border-radius: 5px;
    background-color: ${$isActive
      ? theme.palette.green['400']
      : theme.palette.gray['300']};
    color: ${$isActive
      ? theme.palette.common.white
      : theme.palette.common.black};
    padding: 4px 8px;
    font-size: ${theme.typography.default.fontSize};
    font-weight: ${theme.typography.fontWeightMedium};
    text-transform: uppercase;

    ${theme.breakpoints.down('sm')} {
      padding: 2px 6px;
    }
  `}
`;

export const Info = styled(Wrapper)`
  ${({ theme }) => css`
    align-items: center;

    ${theme.breakpoints.down('lg')} {
      flex-direction: column;
      align-items: flex-start;
    }
  `}
`;

export const Portfolio = styled(Wrapper)`
  ${({ theme }) => css`
    width: 720px;
    margin-right: 21px;
    border-radius: 6px;
    background-color: ${theme.palette.gray['500']};
    padding: 14px 16px;
    justify-content: space-between;

    ${theme.breakpoints.down('lg')} {
      margin-bottom: 8px;
    }
  `}
`;

export const PortfolioItem = styled(Wrapper)`
  ${({ theme }) => css`
    flex-direction: column;

    :nth-child(1) {
      font-size: ${theme.typography.default.fontSize};
    }

    :nth-child(2) {
      font-size: ${theme.typography.xl.fontSize};
      font-weight: ${theme.typography.fontWeightMedium};
    }
  `}
`;

export const Button = styled(ButtonComp)`
  ${({ theme }) => css`
    height: 40px;
    display: flex;
    border-radius: 5px;
    font-weight: ${theme.typography.fontWeightMedium};
    font-size: ${theme.typography.default.fontSize};
    color: ${theme.palette.common.white};
    background: ${theme.palette.common.black};

    ${theme.breakpoints.down('sm')} {
      width: 100%;
    }
  `}
`;

export const SwitchAccountButton = styled(ButtonComp)`
  ${({ theme }) => css`
    display: flex;
    border-radius: 5px;
    font-weight: ${theme.typography.fontWeightMedium};
    font-size: ${theme.typography.default.fontSize};
    color: ${theme.palette.common.white};
    background: ${theme.palette.common.black};
    padding: 4px 8px;

    p {
      font-weight: ${theme.typography.fontWeightMedium};
      font-size: ${theme.typography.default.fontSize};
      text-transform: uppercase;
      color: ${theme.palette.common.white};
    }

    svg {
      width: 10px;
      height: 10px;
      margin: 0 0 2px 6px !important;
    }

    &:disabled {
      p {
        color: unset;
      }

      svg path {
        stroke: ${theme.palette.grey.main};
      }
    }

    ${theme.breakpoints.down('sm')} {
      width: 100%;
      height: 40px;

      p {
        text-transform: unset;
      }
    }
  `}
`;

export const OutlinedButton = styled(ButtonComp)`
  ${({ theme }) => css`
    height: 40px;
    border-radius: 5px;
    font-weight: ${theme.typography.fontWeightMedium};
    font-size: ${theme.typography.default.fontSize};
    color: ${theme.palette.common.black};
    border: 1px solid ${theme.palette.common.black};
    background: ${theme.palette.common.white};

    &:hover {
      color: ${theme.palette.common.black};
      border: 1px solid ${theme.palette.common.black};
      background: ${theme.palette.common.white};
    }
  `}
`;

import React, { useContext, useState } from 'react';

import { useI18next } from '@trader/services';
import { Popover } from '@trader/components';
import { TradingNotificationsContext } from '@trader/contexts';

import { CloseAllOptionsSelection } from './closeAllPositionsSelection';
import * as Styled from './styled';

export const CloseAllPositions: React.FC = () => {
  const { translate } = useI18next();
  const { activateIsCloseAll } = useContext(TradingNotificationsContext);

  const [shouldShowContent, setShouldShowContent] = useState(false);

  return (
    <Popover
      behavior='click'
      elevation={4}
      showContent={shouldShowContent}
      anchorOrigin={{
        vertical: -5,
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      trigger={
        <Styled.Trigger
          onClick={() => {
            setShouldShowContent(true);
          }}
        >
          {translate('COMMON.LABELS.CLOSE_ALL')}
        </Styled.Trigger>
      }
      content={
        <CloseAllOptionsSelection
          onClose={() => {
            activateIsCloseAll();
            setShouldShowContent(false);
          }}
        />
      }
    />
  );
};

import { useEffect } from 'react';

import { getAccountTypeForConnection, productId } from '@trader/constants';
import { getSpreadDifference } from '@trader/utils';
import { IMessage } from '@trader/types';
import { useMst } from '@trader/store';
import {
  EConnectionHub,
  EConnectionSubscription,
  HubConnection,
} from '@trader/services';

import { useStartConnection } from './core';

export const useInstrument = (
  symbol: string,
  spreadDiff: number,
  spreadDiffBalance: number,
  pipSize: number,
  onMessage: (ask: number, bid: number) => void
): void => {
  const store = useMst();

  const { connection, isAppDataLoaded } = useStartConnection(
    EConnectionHub.Quotes,
    EConnectionSubscription.Instrument,
    'quotes'
  );

  const idToken = store.auth.tokens.idToken;
  const activeTradingAccount = store.user.tradingAccount;
  const platformLogin = activeTradingAccount?.platformLogin;
  const accountType = activeTradingAccount?.accountType;
  const product = store.user.getAccountProduct();

  const handleMessage = (message: IMessage) => {
    if (message.s === symbol) {
      const { ask, bid } = getSpreadDifference(
        spreadDiff,
        message.a,
        message.b,
        spreadDiffBalance,
        pipSize
      );
      spreadDiff ? onMessage(ask, bid) : onMessage(message.a, message.b);
    }
  };

  const handleSubscribe = async (hub: HubConnection) => {
    if (symbol && platformLogin && accountType && isAppDataLoaded) {
      await hub.send(
        'SubscribeOnQuote',
        symbol,
        1,
        productId[product],
        platformLogin,
        getAccountTypeForConnection[accountType]
      );
      hub.on('onQuote', handleMessage);
    }
  };

  useEffect(() => {
    connection.subscribe(handleSubscribe);
  }, [symbol, platformLogin, idToken, isAppDataLoaded]);
};

import { IInitializeOutput } from './types';
import { indexDBName, stores } from './constants';

export const initialize = (): Promise<IInitializeOutput> =>
  new Promise(resolve => {
    console.log('Initializing Index DB...');

    const request = indexedDB.open(indexDBName);

    request.onupgradeneeded = event => {
      console.log('onupgradeneeded triggered!');
      const db = request.result;
      console.log('Current DB version:', event.newVersion);
      console.log(
        'Existing object stores before upgrade:',
        db.objectStoreNames
      );

      stores.forEach(store => {
        if (!db.objectStoreNames.contains(store.id)) {
          console.log(`Creating store: ${store.id}`);
          db.createObjectStore(store.id, store.options);
        } else {
          console.log(`Store ${store.id} already exists`);
        }
      });
    };

    request.onsuccess = () => {
      console.log('IndexedDB opened successfully');
      console.log('Index DB version:', request.result.version);
      console.log('Available object stores:', request.result.objectStoreNames);
      resolve({
        readyState: request.readyState,
        version: request.result.version,
      });
    };

    request.onerror = error => {
      console.error('IndexedDB Initializing Error:', error);
      resolve({
        readyState: request.readyState,
        version: request.result.version,
      });
    };
  });

import { FC } from 'react';
import { observer } from 'mobx-react-lite';
import { useTheme } from 'styled-components';

import {
  calcAllPositionsPl,
  getGoalReached,
  getOverAllPl,
} from '@trader/utils';
import { isChallengeAccountType } from '@trader/constants';
import { usePurchase } from '@trader/hooks';
import { useI18next } from '@trader/services';
import { TPositionMetricEntity, useMst } from '@trader/store';
import { GoalReached, InfoChip } from '@trader/components';

import { Logotype } from './components/logotype';
import { TradingChartLayoutSelection } from './components/tradingChartLayoutSelection';
import { DesktopMenu } from './components/menu/desktop';
import { BalanceInformation } from './components/balanceInformation';

import * as Styled from './desktop.styled';

interface IDesktopTopBar {
  withChartLayoutsSelection?: boolean;
}

export const DesktopTopBar: FC<IDesktopTopBar> = observer(
  ({ withChartLayoutsSelection = true }) => {
    const store = useMst();
    const purchase = usePurchase();
    const theme = useTheme();
    const { translate } = useI18next();

    const isDailyTargetGoalReached = store.user.profile.blockedDueToDailyTarget;
    const isDemoAccount = store.user.isDemoAccInUse();

    return (
      <Styled.Root>
        <Logotype />
        <Styled.Content>
          <Styled.Divider $spacingSide='left' />
          {withChartLayoutsSelection && <TradingChartLayoutSelection />}
          <Styled.Divider $spacingSide='right' />
          <BalanceInformation />
          <Styled.Divider $spacingSide='left' />
          {isChallengeAccountType && (
            <>
              <CountedGoalReached />
              <Styled.Divider />
            </>
          )}
          {isDailyTargetGoalReached && (
            <>
              <InfoChip
                iconType='circleCheckmark'
                text={translate('COMMON.LABELS.DAILY_TARGET_REACHED')}
                textColor={theme.palette.common.white}
                backgroundColor={theme.palette.green.dark}
                margin='0 18px'
              />
              <Styled.Divider />
            </>
          )}
          {!isChallengeAccountType && !isDemoAccount && (
            <Styled.AddFunds
              disabled={purchase.isDisabled}
              onClick={purchase.action}
              variant='text'
            >
              {translate(purchase.title)}
            </Styled.AddFunds>
          )}
          <Styled.Divider />
          <DesktopMenu />
        </Styled.Content>
      </Styled.Root>
    );
  }
);

export const CountedGoalReached = observer(() => {
  const store = useMst();

  const activeChallenge = store.user.getActiveAccountChallenge();
  const activeTradingAccount = store.user.tradingAccount;
  const positions =
    store.entities.positionsMetrics.getAll<TPositionMetricEntity>();

  return (
    <GoalReached
      value={getGoalReached(
        activeChallenge?.profitTarget || 0,
        getOverAllPl(
          activeTradingAccount?.portfolio?.balance || 0,
          activeChallenge?.challengeAmount || 0,
          calcAllPositionsPl(positions)
        ),
        activeChallenge?.challengeAmount || 0
      )}
      margin='0 18px'
      hasTitle
    />
  );
});

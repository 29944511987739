import styled, { css } from 'styled-components';

import { Icon } from '@trader/components';

export const CalendarIcon = styled(Icon)`
  ${({ theme }) => css`
    path {
      stroke: ${theme.palette.common.black};
    }
  `};
`;

import { useState } from 'react';
import { useLocation } from 'react-router-dom';

import { IconButton } from '@trader/components';
import { useNavigation } from '@trader/hooks';

import { Logotype } from './components/logotype';
import { MobileMenu } from './components/menu/mobile';

import * as Styled from './mobile.styled';

export const MobileTopBar = () => {
  const { goBackNavigate } = useNavigation();
  const location = useLocation();
  const [menuState, setMenuState] = useState({
    isOpenLeftMenu: false,
    isOpenRightMenu: false,
  });

  const toggleMenuState = (stateKey: keyof typeof menuState) => () => {
    setMenuState(prev => ({
      ...prev,
      [stateKey]: !prev[stateKey],
    }));
  };

  const handleArrowLeftClick = () => {
    goBackNavigate();
  };

  return (
    <Styled.Root>
      {!location.pathname.includes('dashboard') && (
        <IconButton onClick={handleArrowLeftClick} iconType='arrowLeft' />
      )}
      <IconButton
        onClick={toggleMenuState('isOpenLeftMenu')}
        iconType='search'
      />
      <Logotype />
      <IconButton
        iconType='menu'
        id='menu-mobile'
        onClick={toggleMenuState('isOpenRightMenu')}
      />
      <MobileMenu
        side='left'
        isOpen={menuState.isOpenLeftMenu}
        onToggle={toggleMenuState('isOpenLeftMenu')}
      />
      <MobileMenu
        isOpen={menuState.isOpenRightMenu}
        onToggle={toggleMenuState('isOpenRightMenu')}
      />
    </Styled.Root>
  );
};

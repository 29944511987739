import { cast, getRoot, IAnyStateTreeNode } from 'mobx-state-tree';

import {
  api,
  IIsMuliBandsStrategy,
  IMuliBandsEditOrderParams,
  IMuliBandsPlaceOrderBody,
} from '@trader/api';
import {
  TInstrumentEntity,
  TMuliBandsStore,
  TPositionMetricEntity,
  TStripM,
} from '@trader/store';
import { getTime, returnTypedThis, setTime } from '@trader/utils';
import { devLoggerService } from '@trader/services';
import { BANDS, defSl, MODAL_TYPES } from '@trader/constants';

import { TBandModelSnapshotIn } from './bands';
import { createThunk } from '../../utils/asyncModel';
import { getRootInstance } from '../../configureStore/configureStore';

export const getExistingDataAsync = createThunk<string, void>(
  symbol =>
    async function getExistingData(this: unknown, options, _flow) {
      const that = returnTypedThis<TMuliBandsStore>(this);
      const root = getRootInstance();

      try {
        const strategy = await api.BandStrategy.getMuliBands(symbol, options);
        await root.entities.positionsMetrics.getPositionsMetricsAsync.run();

        const obj = Object.values(strategy.bands).reduce<TStripM>(
          (acc, curr) => {
            const result: TStripM = { ...acc };

            if (BANDS[curr.id]) {
              result[curr.id] = {
                id: curr.id,
                orderId: curr.order?.id || null,
                orderSide: curr.order?.side || null,
                positionId: curr.position?.id || null,
                positionPrice: curr.position?.id
                  ? root.entities.positionsMetrics.get<TPositionMetricEntity>(
                      curr.position?.id
                    )?.openPrice
                  : null,
                positionPl: curr.position?.id
                  ? root.entities.positionsMetrics.get<TPositionMetricEntity>(
                      curr.position?.id
                    )?.pl
                  : null,
                value: 0,
              };
            }
            return result;
          },
          {} as TStripM
        );

        const instrument =
          root.entities.instruments.get<TInstrumentEntity>(symbol);
        that.mergeStrips(obj);

        const backTestMultiplier =
          root.pages.muliBands.backTesting.selectedMultiplierOption?.value;

        const bottomMultiplier =
          Number(backTestMultiplier) || strategy.downFloatMultiplier;
        const topMultiplier =
          Number(backTestMultiplier) || strategy.upFloatMultiplier;

        that.runInAction(() => {
          that.id = strategy.id;
          that.firstOpenedPositionTime = strategy.firstOpenedPositionTime;
          that.bandsButtons = cast(parsBands(strategy).bands.reverse());
          that.usSession = strategy.usSession;
          that.staticTopMultiplier = strategy.upMultiplier;
          that.staticBottomMultiplier = strategy.downMultiplier;
          that.topMultiplier = String(topMultiplier);
          that.bottomMultiplier = String(bottomMultiplier);
          that.orderAmount = instrument?.minOrderSize.toString();
          that.isSl = false;
          that.sl = defSl;
          that.isCloseTime = !!strategy.closeTradesAfter;
          that.closeTime = strategy.closeTradesAfter
            ? setTime(strategy.closeTradesAfter)
            : null;
        });
        that.setMultiplier();
      } catch (e) {
        devLoggerService.error('getExistingDataAsync', e);
        that.clear();
      }
    }
);

export const getInitialDataAsync = createThunk<string, void>(
  symbol =>
    async function getInitialData(this: unknown, options, _flow) {
      const that = returnTypedThis<TMuliBandsStore>(this);
      try {
        const resp = await api.BandStrategy.getMuliBandsExisting(
          symbol,
          options
        );

        if (resp.exists) {
          that.getExistingDataAsync.run(symbol);
        } else {
          const res = await api.BandStrategy.initMuliBands({ symbol }, options);
          that.runInAction(() => {
            that.id = res.id;
          });
          that.getExistingDataAsync.run(symbol);
        }
      } catch (e) {
        devLoggerService.error('getInitialDataAsync', e);
        that.clear();
      }
    }
);

export const createBandOrderAsync = createThunk<
  Pick<IMuliBandsPlaceOrderBody, 'side' | 'bandId'>,
  void
>(
  body =>
    async function createBandOrder(this: unknown, options, _flow) {
      const that = returnTypedThis<TMuliBandsStore>(this);
      const root = getRoot(this) as IAnyStateTreeNode;

      const strips = Array.from<TStripM>(that.strips.values());
      const hasSomeOrders = strips.some(strip => strip.orderId);
      const hasSomePositions = strips.some(strip => strip.positionId);

      const submit = async () => {
        try {
          await api.BandStrategy.placeMuliBandsOrder(
            {
              ...body,
              quantity: Number(that.orderAmount),
              strategyId: that.id as number,
              upMultiplier: Number(that.topMultiplier),
              downMultiplier: Number(that.bottomMultiplier),
              stopLoss: that.isSl ? Number(that.sl) : undefined,
              placeDate: !that.isOpenTime
                ? undefined
                : that.openTime
                ? that.openTime.toISOString()
                : undefined,
            },
            options
          );
          that.resetOpenTime();
        } catch (e) {
          devLoggerService.error('createBandOrderAsync', e);
        }
      };

      if (!that.hasMultiplierChanged) {
        await submit();
        return;
      }

      if (that.hasMultiplierChanged && (hasSomePositions || hasSomeOrders)) {
        return root.ui.modal.open(MODAL_TYPES.changingMuliBandsMultiplier, {
          callback: { submit },
        });
      }
      await submit();
    }
);

export const deleteMuliBandAsync = createThunk<void, void>(
  () =>
    async function deleteMuliBand(this: unknown, options, _flow) {
      const that = returnTypedThis<TMuliBandsStore>(this);
      const isOrders = Array.from(that.strips.values()).some(s => s.orderId);
      const isPositions = Array.from(that.strips.values()).some(
        s => s.positionId
      );
      try {
        that.id &&
          !isOrders &&
          !isPositions &&
          (await api.BandStrategy.deleteMuliBand(that.id, options));
      } catch (e) {
        devLoggerService.error('deleteMuliBandAsync', e);
      }
      that.runInAction(() => {
        that.strips.clear();
      });
    }
);

export const editMuliBandsCloseTimeAsync = createThunk<void, void>(
  () =>
    async function editMuliBandsCloseTime(this: unknown, options, _flow) {
      const that = returnTypedThis<TMuliBandsStore>(this);

      try {
        that.id &&
          (await api.BandStrategy.editMuliBandsCloseTime(
            {
              id: that.id,
              closeTradesAfter: !that.isCloseTime
                ? null
                : that.closeTime
                ? getTime(that.closeTime)
                : null,
            },
            options
          ));
      } catch (e) {
        devLoggerService.error('editMuliBandsCloseTimeAsync', e);
      }
    }
);

export const deleteMuliBandOrdersAsync = createThunk<void, void>(
  () =>
    async function deleteMuliBandOrders(this: unknown, options, _flow) {
      const that = returnTypedThis<TMuliBandsStore>(this);
      if (!that.id) {
        return;
      }
      try {
        await api.BandStrategy.deleteMuliBandOrders(that.id, options);
        that.runInAction(() => {
          const strips = Array.from(that.strips.values());
          const res = strips.reduce<TStripM>(
            (acc, item) => ({
              ...acc,
              [item.id]: {
                ...item,
                orderId: null,
              },
            }),
            {} as TStripM
          );
          that.mergeStrips(res);
        });
      } catch (e) {
        devLoggerService.error('deleteMuliBandOrdersAsync', e);
      }
    }
);

export const deleteMuliBandSingleOrderAsync = createThunk<string, void>(
  bandId =>
    async function deleteMuliBandSingleOrder(this: unknown, options, _flow) {
      const that = returnTypedThis<TMuliBandsStore>(this);
      if (!that.id) {
        return;
      }
      try {
        const orderId = that.strips.get(bandId)?.orderId;

        if (!orderId) {
          return;
        }

        await api.BandStrategy.deleteMuliBandSingleOrder(
          bandId,
          that.id,
          options
        );

        that.updateStripOrder(bandId, null);
      } catch (e) {
        devLoggerService.error('deleteMuliBandSingleOrderAsync', e);
      }
    }
);

export const editMuliBandsOrderAsync = createThunk<
  Omit<IMuliBandsEditOrderParams, 'id'>,
  void
>(
  params =>
    async function editMuliBandsOrder(this: unknown, options, _flow) {
      const that = returnTypedThis<TMuliBandsStore>(this);
      if (!that.id) {
        return;
      }

      try {
        await api.BandStrategy.editMuliBandsOrder(
          { ...params, id: that.id },
          options
        );

        that.updateStripOrder(params.bandId, null);
      } catch (e) {
        devLoggerService.error('editMuliBandsOrderAsync', e);
      }
    }
);

export const pingMuliBandAsync = createThunk<void, void>(
  () =>
    async function pingMuliBand(this: unknown, options, _flow) {
      const that = returnTypedThis<TMuliBandsStore>(this);
      try {
        that.id &&
          (await api.BandStrategy.pingMuliBand({ id: that.id }, options));
      } catch (e) {
        devLoggerService.error('pingMuliBandAsync', e);
      }
    }
);

interface IParsBandsOutput {
  bands: Array<TBandModelSnapshotIn>;
}

const parsBands = (strategy: IIsMuliBandsStrategy) => {
  return Object.values(strategy.bands).reduce<IParsBandsOutput>((acc, curr) => {
    const result: IParsBandsOutput = {
      ...acc,
      bands: acc?.bands ? [...acc.bands] : [],
    };

    if (BANDS[curr.id]) {
      result.bands.push({
        value: BANDS[curr.id]?.value,
        order: BANDS[curr.id]?.order,
        id: curr.id,
        type: BANDS[curr.id]?.type,
        title: BANDS[curr.id]?.title,
      });
    }
    return result;
  }, {} as IParsBandsOutput);
};

import { TPositionMetricEntity, useMst } from '@trader/store';
import { calcAsset } from '@trader/utils';

export const usePositionMetricsAssets = () => {
  const store = useMst();
  const positions =
    store.entities.positionsMetrics.getAll<TPositionMetricEntity>();

  const assets = positions.reduce(
    (acc, p) => calcAsset(p.price, p.quantity) + acc,
    0
  );

  return { positionsAssets: assets };
};

import { FC } from 'react';
import { useTheme, useMediaQuery } from '@mui/material';
import { observer } from 'mobx-react-lite';

import { TInstrumentEntity, useMst } from '@trader/store';
import { EChartLayouts, EChartLayoutTypes } from '@trader/types';

import { MobileInstrumentDetailsHeader } from './mobile';
import { DesktopInstrumentDetailsHeader } from './desktop';

interface IInstrumentWithLayout extends TInstrumentEntity {
  layoutNumber?: EChartLayouts;
}
export interface IInstrumentDetailsHeader {
  instrument?: IInstrumentWithLayout;
  layout?: EChartLayoutTypes;
  layoutNumber?: EChartLayouts;
  searchPlaceholder?: string;
  searchCategory?: string;
  isMuliBandsStrategy?: boolean;
  onChangeSymbol?: (symbol: string) => void;
}

export const InstrumentDetailsHeader: FC<IInstrumentDetailsHeader> = observer(
  props => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const store = useMst();
    const symbol = store.pages.trading.getInstrumentSymbolByLayout();
    const storeInstrument =
      store.entities.instruments.get<TInstrumentEntity>(symbol);
    const instrument = props.instrument || {
      ...storeInstrument,
      layoutNumber: EChartLayouts.FirstLayout,
    };

    if (!instrument?.symbol) {
      return null;
    }

    return isMobile ? (
      <MobileInstrumentDetailsHeader {...props} instrument={instrument} />
    ) : (
      <DesktopInstrumentDetailsHeader {...props} instrument={instrument} />
    );
  }
);

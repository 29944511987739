import {
  // useCallback,
  useEffect,
} from 'react';

import { useGetPageVisibility } from './useGetPageVisibility';
import {
  LOCAL_STORAGE_KEYS,
  // MODAL_TYPES,
  // oneSecond
} from '@trader/constants';
import { useMst } from '@trader/store';
// import {
//   checkUserActivitySecondsFromStorage,
//   setUserActivityToStorage,
// } from '@trader/utils';
import { localStorageService } from '@trader/services';

const timeToLogout = 3600000; // 1 hour;

// const activityEvents = [
//   'mousedown',
//   'mousemove',
//   'keydown',
//   'scroll',
//   'touchstart',
// ];

/**
 * Custom hook that automatically logs out the user after a period of inactivity.
 * It tracks user activity and displays a modal before logging out.
 */
export const useAutoLogoutWithNoActivity = () => {
  const store = useMst();
  const isVisible = useGetPageVisibility();

  const isAuth = store.auth.isAuth;
  // const userActivity = store.auth.userActivity;

  // const activityWatcher = useCallback(() => {
  //   userActivity.countUpSeconds();
  // }, []);
  //
  // const resetSeconds = useCallback(() => {
  //   userActivity.resetSeconds();
  // }, []);

  useEffect(() => {
    if (!isAuth) {
      return;
    }

    if (isVisible) {
      const leftPageTimestamp = localStorageService.get(
        LOCAL_STORAGE_KEYS.leftPageTimestamp
      );

      if (!leftPageTimestamp) {
        return;
      }

      const currentDate = new Date().getTime();

      if (currentDate - Number(leftPageTimestamp) >= timeToLogout) {
        store.ui.modal.close();
        store.auth.logOut();
      }
    } else {
      localStorageService.set(
        LOCAL_STORAGE_KEYS.leftPageTimestamp,
        JSON.stringify(new Date().getTime())
      );
    }
  }, [isAuth, isVisible]);

  // useEffect(() => {
  //   const isLogOutWithNoActivityModal =
  //     store.ui.modal.id === MODAL_TYPES.logOutWithNoActivity &&
  //     store.ui.modal.isOpen;
  //
  //   if (userActivity.secondsWithNoActivity === timeInSecondsToLogout) {
  //     store.ui.modal.open(MODAL_TYPES.logOutWithNoActivity, {
  //       shouldHideCloseButton: true,
  //     });
  //   } else if (
  //     userActivity.secondsWithNoActivity > timeInSecondsToLogout &&
  //     !isLogOutWithNoActivityModal
  //   ) {
  //     store.ui.modal.close();
  //     store.auth.logOut();
  //   }
  // }, [userActivity.secondsWithNoActivity]);
};

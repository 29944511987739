import { FC, MouseEvent } from 'react';

import { MODAL_TYPES } from '@trader/constants';
import { TOrderMetricEntity, useMst } from '@trader/store';
import { ScrollableItemActions } from '@trader/components';

interface IPendingOrderActions {
  pendingOrder: TOrderMetricEntity;
}

export const PendingOrderActions: FC<IPendingOrderActions> = ({
  pendingOrder,
}) => {
  const store = useMst();

  const handleEditClick = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    store.ui.modal.open(MODAL_TYPES.editOrder, {
      symbol: pendingOrder.symbol,
      orderId: pendingOrder.orderId,
      side: pendingOrder.side,
    });
  };

  const handleRemoveClick = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    store.ui.modal.open(MODAL_TYPES.cancelOrder, {
      symbol: pendingOrder.symbol,
      orderId: pendingOrder.orderId,
      side: pendingOrder.side,
    });
  };

  return (
    <ScrollableItemActions
      onEditClick={handleEditClick}
      onRemoveClick={handleRemoveClick}
    />
  );
};

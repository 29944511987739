import { api } from '@trader/api';
import { formatByPipSize } from '@trader/utils';
import { ILibrarySymbolInfo } from './types';
import {
  DatafeedErrorCallback,
  HistoryCallback,
  PeriodParams,
  ResolutionString,
  SubscribeBarsCallback,
} from './charting_library';
import {
  rootStore,
  TInstrumentEntity,
  TTradingAccountEntity,
} from '@trader/store';
import {
  defaultPipSize,
  emptyFn,
  convertTimeToUtc,
  BANDS,
  productId,
  getAccountTypeForConnection,
} from '@trader/constants';
import { resetChartData } from '@trader/containers';
import {
  EConnectionHub,
  EConnectionSubscription,
  webSocketsService,
  webSocketsUrls,
  devLoggerService,
} from '@trader/services';
import { IMessage } from '@trader/types';
import {
  lastBarResolutions,
  resetLastBarResolutions,
  resolutions,
  invertedResolutions,
  TKeyResolutions,
  IResolution,
} from './utils';

export { resolutions, invertedResolutions };

export let resetCacheNeededCallback = emptyFn;

// DatafeedConfiguration implementation
const configurationData = {
  // Represents the resolutions for bars supported by your datafeed
  supported_resolutions: Object.keys(resolutions) as Array<ResolutionString>,
  // The `exchanges` arguments are used for the `searchSymbols` method if a user selects the exchange
  exchanges: [{ value: '' }],
  // The `symbols_types` arguments are used for the `searchSymbols` method if a user selects this symbol type
  symbols_types: [{ value: '' }],
};
let firstBarTime = 0;
const muliBandsLastBar = {
  time: 0,
  volume: 0,
  open: 0,
  high: 0,
  low: 0,
  close: 0,
};

const connection = webSocketsService({
  hub: EConnectionHub.TradingView,
  subscription: EConnectionSubscription.TradingView,
  url: webSocketsUrls.quotes,
  logout: rootStore.auth.logOut,
  refreshToken: async () => {
    await rootStore.auth.authRefreshTokenAsync.run();
  },
});

export default (
  getSymbolData: (symbol: string) => TInstrumentEntity,
  activeTradingAccount: TTradingAccountEntity,
  idToken: string,
  isMuliBandsStrategy?: boolean
) => ({
  onReady: (callback: (config: typeof configurationData) => void) => {
    setTimeout(() => callback(configurationData));
  },
  searchSymbols: emptyFn,
  resolveSymbol: (
    symbol: string,
    onSymbolResolvedCallback: (symbolInfo: ILibrarySymbolInfo) => void
  ) => {
    // do not trigger requests if reset data
    if (symbol === resetChartData) {
      return;
    }

    const data = getSymbolData(symbol);

    const pricescale = (data?.pipSize || defaultPipSize)
      .toString()
      .split('.')
      .join('')
      .split('')
      .reverse()
      .join('');

    const symbolInfo: ILibrarySymbolInfo = {
      ticker: symbol as string,
      name: symbol as string,
      description: (data?.description as string) || '',
      pipSize: data?.pipSize || defaultPipSize,
      closePrice: data?.close,
      supported_resolutions: configurationData.supported_resolutions,
      type: data?.category || '',
      session: '24x7',
      timezone: 'Etc/UTC',
      exchange: '',
      minmov: 1,
      pricescale: Number(pricescale),
      has_intraday: true,
      visible_plots_set: 'ohlc',
      has_weekly_and_monthly: true,
      volume_precision: 2,
      data_status: 'streaming',
    };

    setTimeout(() => onSymbolResolvedCallback(symbolInfo));
  },

  getBars: async (
    symbolInfo: ILibrarySymbolInfo,
    resolutionKey: TKeyResolutions,
    periodParams: PeriodParams,
    onHistoryCallback: HistoryCallback,
    onErrorCallback: DatafeedErrorCallback
  ) => {
    try {
      const response = await api.Historical.getCandleBars(
        symbolInfo.name,
        resolutions[resolutionKey],
        periodParams.firstDataRequest ? 0 : firstBarTime
      );

      if (response?.length === 0) {
        onHistoryCallback([], { noData: true });
        return;
      }

      // eslint-disable-next-line require-atomic-updates
      firstBarTime = response[0].t;

      if (periodParams.firstDataRequest) {
        resetLastBarResolutions();

        lastBarResolutions.set(resolutionKey, {
          ...(lastBarResolutions.get(resolutionKey) as IResolution),
          symbol: symbolInfo.name,
          low: response[response.length - 1].l,
          close: response[response.length - 1].c,
          high: response[response.length - 1].h,
          open: response[response.length - 1].o,
          volume: response[response.length - 1].v,
          time: response[response.length - 1].t * convertTimeToUtc,
        });

        if (isMuliBandsStrategy) {
          muliBandsLastBar.time =
            response[response.length - 1].t * convertTimeToUtc;
          muliBandsLastBar.low = response[response.length - 1].l;
          muliBandsLastBar.close = response[response.length - 1].c;
          muliBandsLastBar.high = response[response.length - 1].h;
          muliBandsLastBar.open = response[response.length - 1].o;
          muliBandsLastBar.volume = response[response.length - 1].v;
          rootStore.pages.muliBands.setPrice(muliBandsLastBar.close);
          rootStore.pages.muliBands.setMultiplier(
            new Date(muliBandsLastBar.time)
          );
        }

        setTimeout(() =>
          onHistoryCallback(
            response.map((bar, index) => ({
              time: bar.t * convertTimeToUtc,
              low: bar.l,
              high: bar.h,
              open: bar.o,
              close: bar.c,
              volume: index + 1 === response.length ? 0 : bar.v,
            })),
            { noData: false }
          )
        );
      } else {
        // fetching more bars while scrolling
        setTimeout(() =>
          onHistoryCallback(
            response.map(bar => ({
              time: bar.t * convertTimeToUtc,
              low: bar.l,
              high: bar.h,
              open: bar.o,
              close: bar.c,
              volume: bar.v,
            })),
            { noData: false }
          )
        );
      }
    } catch (error) {
      devLoggerService.error('getBarsError', error);
      onErrorCallback(error as string);
    }
  },

  subscribeBars: (
    symbolInfo: ILibrarySymbolInfo,
    resolutionKey: TKeyResolutions,
    onRealtimeCallback: SubscribeBarsCallback,
    _listenerGuid: string,
    onResetCacheNeededCallback: () => void
  ) => {
    resetCacheNeededCallback = onResetCacheNeededCallback;

    const resolution = lastBarResolutions.get(resolutionKey) as IResolution;

    const handleMessage = (message: IMessage) => {
      if (resolution.symbol !== symbolInfo.name) {
        return;
      }

      if (message.s === symbolInfo.name) {
        if (
          isMuliBandsStrategy &&
          message.s === rootStore.pages.muliBands.symbol
        ) {
          const instrument =
            rootStore.entities.instruments.get<TInstrumentEntity>(
              rootStore.pages.muliBands.symbol
            );

          const price = message.p
            ? Number(
                formatByPipSize(
                  message.p,
                  instrument.pipSize || symbolInfo.pipSize
                )
              )
            : null;

          const handleNewBar = (bar: IResolution) => {
            onRealtimeCallback(muliBandsLastBar);

            muliBandsLastBar.time = bar.time;
            muliBandsLastBar.volume = 0;
            muliBandsLastBar.open = bar.open;
            muliBandsLastBar.high = bar.high;
            muliBandsLastBar.low = bar.low;
            muliBandsLastBar.close = bar.close;

            const vwap = rootStore.pages.muliBands.strips.get(
              BANDS.RoundedVwap.id
            );
            if (vwap && vwap.value) {
              if (muliBandsLastBar.volume !== vwap.value) {
                rootStore.pages.muliBands.onRecalculate();
              }
            }
          };
          const handleOldBar = (bar: IResolution) => {
            if (price) {
              muliBandsLastBar.open = bar.open;
              muliBandsLastBar.high = bar.high;
              muliBandsLastBar.low = bar.low;
              muliBandsLastBar.close = bar.close;
            }
            muliBandsLastBar.volume += message.v;
            onRealtimeCallback({ ...bar, volume: 0 });
          };

          if (price) {
            resolution.getBar(
              message.t,
              price,
              message.v,
              handleNewBar,
              handleOldBar
            );
            rootStore.pages.muliBands.symbol === symbolInfo.name &&
              rootStore.pages.muliBands.setPrice(price);
          }

          rootStore.pages.muliBands.setMultiplier(new Date(message.t));
        } else {
          const price = message.p
            ? Number(formatByPipSize(message.p, symbolInfo.pipSize))
            : null;

          if (price) {
            const lastBar = resolution.getBar(message.t, price, message.v);
            setTimeout(() => onRealtimeCallback(lastBar));
          }
        }
      }
    };
    connection.start(idToken);
    connection.subscribe(async hub => {
      await hub.send(
        'SubscribeOnQuote',
        symbolInfo.name,
        1,
        productId[activeTradingAccount.product],
        activeTradingAccount.platformLogin,
        getAccountTypeForConnection[activeTradingAccount.accountType]
      );
      hub.on('onQuote', handleMessage);
    });
  },
  unsubscribeBars: emptyFn,
});

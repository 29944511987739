import React from 'react';

import { TChallengeEntity, TTradingAccountEntity, useMst } from '@trader/store';
import { EChallenge, EChallengeStatus } from '@trader/types';
import { Icon, Typography, Wrapper } from '@trader/components';
import { useI18next } from '@trader/services';

import * as Styled from './desktop.styled';

export const ChangeAccountButton: React.FC<{
  account: TTradingAccountEntity;
  challenge: TChallengeEntity | null;
}> = ({ account, challenge }) => {
  const { translate } = useI18next();
  const store = useMst();

  const handleButtonClick = () => {
    store.entities.tradingAccounts.changeTradingAccountAsync.run({
      tradingId: account.tradingId,
    });
  };

  return (
    <Styled.SwitchAccountButton
      disabled={
        account.isInUse ||
        challenge?.status === EChallengeStatus.Disable ||
        challenge?.hierarchyType === EChallenge.Slave
      }
      onClick={handleButtonClick}
    >
      <Wrapper alignItems='center'>
        <Typography>{translate('COMMON.LABELS.SWITCH')}</Typography>
        <Icon iconType='switchAccount' />
      </Wrapper>
    </Styled.SwitchAccountButton>
  );
};

/* eslint-disable no-magic-numbers */
export const resolutions = {
  '1': 'Minute1',
  '2': 'Minute2',
  '5': 'Minute5',
  '15': 'Minute15',
  '30': 'Minute30',
  '60': 'Hour1',
  '120': 'Hour2',
  '240': 'Hour4',
  '1D': 'Day1',
  '1W': 'Week1',
  '1M': 'Month1',
} as const;

export const invertedResolutions = {
  Minute1: '1',
  Minute2: '2',
  Minute5: '5',
  Minute15: '15',
  Minute30: '30',
  Hour1: '60',
  Hour2: '120',
  Hour4: '240',
  Day1: '1D',
  Week1: '1W',
  Month1: '1M',
} as const;

export type TKeyResolutions = keyof typeof resolutions;

export interface IResolution {
  time: number;
  low: number;
  high: number;
  open: number;
  close: number;
  volume: number;
  symbol: string;
  getBar: ReturnType<typeof getLastBar>;
}

const resolutionsKeys = Object.keys(resolutions) as Array<TKeyResolutions>;

function getCurrentPeriodTime(period: TKeyResolutions, date: Date) {
  let day = date.getUTCDate();
  let hour = date.getUTCHours();
  let minute = date.getUTCMinutes();

  switch (period) {
    case '1M':
      day = 1;
      hour = 0;
      minute = 0;
      break;
    case '1W': {
      const dayOfWeek = date.getDay() || 7;
      date.setDate(date.getDate() + (7 - dayOfWeek));

      day = date.getDate();
      hour = 0;
      minute = 0;
      break;
    }
    case '1D':
      hour = 0;
      minute = 0;
      break;
    case '240':
      hour -= hour % 4;
      minute = 0;
      break;
    case '120':
      hour -= hour % 2;
      minute = 0;
      break;
    case '60':
      minute = 0;
      break;
    case '30':
      minute -= minute % 30;
      break;
    case '15':
      minute -= minute % 15;
      break;
    case '5':
      minute -= minute % 5;
      break;
    case '2':
      minute -= minute % 2;
      break;
    case '1':
    default:
      break;
  }

  return new Date(
    Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), day, hour, minute, 0, 0)
  );
}

function getLastBar(resolution: TKeyResolutions) {
  return function (
    time: number,
    price: number,
    volume: number,
    onNewBar?: (bar: IResolution) => void,
    onOldBar?: (bar: IResolution) => void
  ) {
    const periodTime = getCurrentPeriodTime(resolution, new Date(time));

    if (periodTime.getTime() === new Date(this.time).getTime()) {
      this.high = Math.max(this.high, price);
      this.low = Math.min(this.low, price);
      this.volume = this.volume + volume;
      this.close = price;
      onOldBar?.(this);
    } else {
      this.open = price;
      this.high = price;
      this.low = price;
      this.close = price;
      this.volume = 0;
      this.time = periodTime.getTime();
      onNewBar?.(this);
    }

    return this;
  };
}

export const lastBarResolutions = new Map<TKeyResolutions, IResolution>();

resolutionsKeys.forEach(key => {
  lastBarResolutions.set(key, {
    time: 0,
    low: 0,
    high: 0,
    open: 0,
    close: 0,
    volume: 0,
    symbol: '',
    getBar: getLastBar(key),
  });
});

export const resetLastBarResolutions = () => {
  resolutionsKeys.forEach(key => {
    lastBarResolutions.set(key, {
      time: 0,
      low: 0,
      high: 0,
      open: 0,
      close: 0,
      volume: 0,
      symbol: '',
      getBar: getLastBar(key),
    });
  });
};

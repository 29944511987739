import { getRoot, SnapshotIn, types } from 'mobx-state-tree';

import { IUserPortfolio } from '@trader/api';
import { shouldDisplayCreditInPortfolio } from '@trader/constants';

import { IRootStoreInstance } from '../../configureStore';

type TPortfolioUI = Record<keyof IUserPortfolio, TPortfolioUIItemModelIn>;

const selectedItems: TPortfolioUI = {
  balance: {
    id: 'balance',
    title: 'COMMON.LABELS.BALANCE',
    tooltip: 'TOOLTIPS.BALANCE',
    isSelected: true,
    isVisible: true,
  },
  credit: {
    id: 'credit',
    title: 'COMMON.LABELS.CREDIT',
    tooltip: 'TOOLTIPS.CREDIT',
    isSelected: false,
    isVisible: shouldDisplayCreditInPortfolio,
  },
  equity: {
    id: 'equity',
    title: 'COMMON.LABELS.EQUITY',
    tooltip: 'TOOLTIPS.EQUITY',
    isSelected: false,
    isVisible: true,
  },
  assets: {
    id: 'assets',
    title: 'COMMON.LABELS.ASSETS',
    tooltip: 'COMMON.LABELS.ASSETS',
    isSelected: false,
    isVisible: true,
  },
  startDailyPl: {
    id: 'startDailyPl',
    title: 'COMMON.LABELS.P_L',
    tooltip: 'TOOLTIPS.P_L',
    isSelected: false,
    isVisible: false,
  },
  dailyPl: {
    id: 'dailyPl',
    title: 'COMMON.LABELS.P_L',
    tooltip: 'TOOLTIPS.P_L',
    isSelected: false,
    isVisible: true,
  },
  profit: {
    id: 'profit',
    title: 'COMMON.LABELS.PROFIT',
    tooltip: 'COMMON.LABELS.PROFIT',
    isSelected: false,
    isVisible: false,
  },
  freeMargin: {
    id: 'freeMargin',
    title: 'COMMON.LABELS.FREE_MARGIN',
    tooltip: 'TOOLTIPS.FREE_MARGIN',
    isSelected: false,
    isVisible: true,
  },
  usedMargin: {
    id: 'usedMargin',
    title: 'COMMON.LABELS.USED_MARGIN',
    tooltip: 'TOOLTIPS.USED_MARGIN',
    isSelected: false,
    isVisible: true,
  },
  availableForWithdrawal: {
    id: 'availableForWithdrawal',
    title: 'COMMON.LABELS.AVAILABLE_FOR_WITHDRAWAL',
    tooltip: 'COMMON.LABELS.AVAILABLE_FOR_WITHDRAWAL',
    isSelected: false,
    isVisible: false,
  },
  marginLevel: {
    id: 'marginLevel',
    title: 'COMMON.LABELS.MARGIN_LEVEL',
    tooltip: 'TOOLTIPS.MARGIN_LEVEL',
    isSelected: false,
    isVisible: true,
  },
  maintenanceMargin: {
    id: 'maintenanceMargin',
    title: 'COMMON.LABELS.MARGIN',
    tooltip: 'COMMON.LABELS.MARGIN',
    isSelected: false,
    isVisible: false,
  },
};

const portfolioUIItemModel = types.model('portfolioUIItem', {
  id: types.frozen<keyof IUserPortfolio>(),
  title: types.string,
  tooltip: types.string,
  isSelected: types.boolean,
  isVisible: types.boolean,
});

const portfolioUIModel = types
  .model('portfolioUI', {
    items: types.optional(types.map(portfolioUIItemModel), selectedItems),
  })
  .views(store => ({
    getSelectedItems: (): TPortfolioUIItemModelIn[] => {
      const root: IRootStoreInstance = getRoot(store);
      const isInvestmentAccProduct = root.user.isInvestmentAccProduct();

      return Array.from(store.items.values()).filter(i => {
        if (i.id === 'assets' && !isInvestmentAccProduct) {
          return false;
        }

        return i.isSelected && i.id !== 'balance';
      });
    },
    getIsSelected: (key: keyof IUserPortfolio): boolean => {
      const item = store.items.get(key);
      return item?.isSelected || false;
    },
    getBalanceItem: (): TPortfolioUIItemModelIn => {
      return store.items.get('balance') as TPortfolioUIItemModelIn;
    },
    getVisibleItems: (): TPortfolioUIItemModelIn[] => {
      const root: IRootStoreInstance = getRoot(store);
      const isInvestmentAccProduct = root.user.isInvestmentAccProduct();

      return Array.from(store.items.values()).filter(i => {
        if (i.id === 'assets' && !isInvestmentAccProduct) {
          return false;
        }

        return i.isVisible;
      });
    },
  }))
  .actions(store => ({
    toggleItem(key: keyof IUserPortfolio) {
      const item = store.items.get(key);
      if (item && key !== 'balance') {
        store.items.set(key, {
          ...item,
          isSelected: !item.isSelected,
        });
      }
    },
  }));

export const portfolio = types.optional(portfolioUIModel, {});

export type TPortfolioUIItemModelIn = SnapshotIn<typeof portfolioUIItemModel>;

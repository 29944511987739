import styled, { css } from 'styled-components';
import { Typography, Wrapper } from '@trader/components';

export const Root = styled(Wrapper)`
  ${() => css`
    width: 100%;
    height: 100%;
    overflow: auto;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  `}
`;
export const Header = styled(Wrapper)`
  ${({ theme }) => css`
    width: 100%;
    align-items: center;
    padding: 3px 11px;
    border-bottom: 1px solid ${theme.palette.background.default};

    ${theme.breakpoints.down('sm')} {
      border-top: 5px solid ${theme.palette.background.default};
    }
  `}
`;

export const Instrument = styled(Typography)`
  ${({ theme }) => css`
    min-width: 55px;
    max-width: 55px;
    font-weight: ${theme.typography.fontWeightMedium};
    color: ${theme.palette.grey['400']};
    flex: 1;
    margin-right: 6px;

    ${theme.breakpoints.down('sm')} {
      min-width: 72px;
      max-width: 72px;
    }
  `};
`;
export const Sell = styled(Typography)`
  ${({ theme }) => css`
    min-width: 65px;
    max-width: 65px;
    font-weight: ${theme.typography.fontWeightMedium};
    color: ${theme.palette.grey['400']};
    flex: 1;
    text-align: center;
    padding: 3px 0;
    border-radius: 5px;
    margin-right: 5px;

    ${theme.breakpoints.down('sm')} {
      min-width: 75px;
      max-width: 75px;
    }
  `};
`;
export const Buy = styled(Typography)`
  ${({ theme }) => css`
    min-width: 65px;
    max-width: 65px;
    font-weight: ${theme.typography.fontWeightMedium};
    color: ${theme.palette.grey['400']};
    flex: 1;
    text-align: center;
    padding: 3px 0;
    border-radius: 5px;

    ${theme.breakpoints.down('sm')} {
      min-width: 75px;
      max-width: 75px;
    }
  `};
`;
export const Spread = styled(Typography)`
  ${({ theme }) => css`
    max-width: 52px;
    min-width: 52px;
    font-weight: ${theme.typography.fontWeightMedium};
    color: ${theme.palette.grey['400']};
    flex: 1;
    text-align: center;
    padding: 3px 0;
    border-radius: 5px;
  `};
`;
export const HTrend = styled(Typography)`
  ${({ theme }) => css`
    min-width: 50px;
    max-width: 50px;
    font-weight: ${theme.typography.fontWeightMedium};
    display: flex;
    flex: 1;
    justify-content: center;
    color: ${theme.palette.grey['400']};
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;

    ${theme.breakpoints.down('sm')} {
      display: none;
    }
  `};
`;
export const Favourite = styled(Typography)`
  ${({ theme }) => css`
    min-width: 35px;
    max-width: 35px;

    ${theme.breakpoints.down('sm')} {
      display: none;
    }
  `};
`;

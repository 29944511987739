import { useContext, useEffect } from 'react';

import {
  EConnectionHub,
  EConnectionSubscription,
  HubConnection,
} from '@trader/services';
import { ESocketUpdateAction, IPositionMessage } from '@trader/types';
import { getAccountTypeForConnection, productId } from '@trader/constants';
import { TPositionMetricEntity, useMst } from '@trader/store';
import { TradingNotificationsContext } from '@trader/contexts';

import { useStartConnection } from './core';

/**
 * Custom hook that manages position updates by subscribing to a hub
 * and handling incoming messages to update the store.
 */
export const usePositions = () => {
  const store = useMst();
  const { handleSaveMessage } = useContext(TradingNotificationsContext);

  const { connection, isAppDataLoaded } = useStartConnection(
    EConnectionHub.Account,
    EConnectionSubscription.Positions,
    'account'
  );

  const idToken = store.auth.tokens.idToken || '';
  const activeTradingAccount = store.user.tradingAccount;
  const platformLogin = activeTradingAccount?.platformLogin || '';
  const accountType = activeTradingAccount?.accountType || '';
  const product = store.user.getAccountProduct();

  const handleSubscribe = async (hub: HubConnection) => {
    if (platformLogin && accountType && product) {
      await hub.send(
        'SubscribeOnPositions',
        platformLogin || null,
        productId[product],
        getAccountTypeForConnection[accountType]
      );
      hub.on('position', handleMessage);
    }
  };

  const handleMessage = (message: IPositionMessage) => {
    switch (message.updateAction) {
      case ESocketUpdateAction.Create:
        handleSaveMessage({ type: 'position', ...message });
        break;
      case ESocketUpdateAction.Delete:
        handleSaveMessage({ type: 'position', ...message });
        break;
      case ESocketUpdateAction.Update: {
        const currentPosition =
          store.entities.positionsMetrics.get<TPositionMetricEntity>(
            message.id
          );

        if (currentPosition) {
          const isPartiallyClosed = currentPosition?.quantity > message.volume;
          if (isPartiallyClosed) {
            message.updateAction = 2;
            message.volume = currentPosition?.quantity - message.volume;
          }
        }

        handleSaveMessage({ type: 'position', ...message });
        break;
      }
      default:
        break;
    }
  };

  useEffect(() => {
    isAppDataLoaded && connection.subscribe(handleSubscribe);
    return () => {
      if (platformLogin && accountType && product) {
        connection.unsubscribe(async hub => {
          await hub?.send(
            'UnsubscribeFromPositions',
            platformLogin || null,
            productId[product],
            getAccountTypeForConnection[accountType]
          );
        });
      }
    };
  }, [platformLogin, idToken, isAppDataLoaded]);
};

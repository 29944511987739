import { FC } from 'react';
import { observer } from 'mobx-react-lite';

import {
  IScrollableListItemDetail,
  ScrollableList,
  ScrollableListItem,
  SymbolWithIcon,
} from '@trader/components';
import { TAlertEntity } from '@trader/store';

import { formatDate } from '@trader/utils';
import { useI18next } from '@trader/services';
import { iconSizes } from '@trader/constants';

import { useAlertsListData } from './hooks/useAlertsListData';
import { AlertActions } from './components/alertActions';
import { AlertSide } from './components/alertSide';
import { AlertPrice } from './components/alertPrice';
import { AlertCurrentPrice } from './components/alertCurrentPrice';

export const MobileAlertsList: FC = observer(() => {
  const { alerts } = useAlertsListData();

  return (
    <ScrollableList<TAlertEntity>
      data={alerts}
      renderItem={a => <AlertListItem alert={a} />}
    />
  );
});

const AlertListItem: FC<{ alert: TAlertEntity }> = observer(({ alert }) => {
  const { translate, currentLng } = useI18next();

  const detailsItems: IScrollableListItemDetail[] = [
    {
      id: 'side',
      header: translate('COMMON.LABELS.TYPE'),
      value: <AlertSide alert={alert} />,
    },
    {
      id: 'name',
      header: translate('COMMON.LABELS.CREATION_DATE'),
      value: formatDate(new Date(alert.name), 'Mm dd, yyyy hh:mm:ss', {
        locale: currentLng,
      }),
    },
    {
      id: 'endTime',
      header: translate('COMMON.LABELS.EXPIRATION_DATE'),
      value: alert.endTime
        ? formatDate(
            new Date(alert.endTime as string),
            'Mm dd, yyyy hh:mm:ss',
            { locale: currentLng }
          )
        : '',
    },
    {
      id: 'alertPrice',
      header: translate('COMMON.LABELS.ALERT_WHEN'),
      value: <AlertPrice alert={alert} />,
    },
    {
      id: 'currentPrice',
      header: translate('COMMON.LABELS.CURRENT_PRICE'),
      value: <AlertCurrentPrice alert={alert} />,
    },
  ];

  return (
    <ScrollableListItem
      leadingContent={
        <SymbolWithIcon
          symbol={alert.conditions[0].instrument.symbol}
          iconUrl={alert.conditions[0].instrument.iconUrl}
          iconSize={iconSizes.small}
          textVariant='medium'
        />
      }
      trailingContent={<AlertActions alert={alert} />}
      detailsItems={detailsItems}
    />
  );
});

import Axios, {
  AxiosInstance,
  AxiosResponse,
  CancelTokenStatic,
  CancelToken,
  AxiosRequestConfig,
  InternalAxiosRequestConfig,
} from 'axios';

export type THttpClientInstance = AxiosInstance;
export type THttpClientResponse<T> = AxiosResponse<T>;

export type THttpClientRequestConfig = AxiosRequestConfig;
export type THttpClientInternalRequestConfig = InternalAxiosRequestConfig;

// THttpResponse is util type for reduce manually wright Promise<T>
export type THttpResponse<T> = Promise<THttpClientResponse<T>>;

export type TBEResponse<T> = { result: T; message: string };

export const httpClient: THttpClientInstance = Axios.create({
  baseURL: import.meta.env.VITE_BASE_URL,
  headers: {
    'Clear-Site-Data': 'cache',
    os: 'web',
    Brand: import.meta.env.VITE_BRAND,
    Product: import.meta.env.VITE_PRODUCT,
    'X-Api-Version': 'v2',
  },
  paramsSerializer: params => {
    return Object.keys(params)
      .map(key => {
        const value = params[key];

        if (value === undefined || (Array.isArray(value) && !value.length)) {
          return null;
        }

        if (Array.isArray(value)) {
          return value.map(v => `${key}=${encodeURIComponent(v)}`).join('&');
        }

        return `${key}=${encodeURIComponent(value)}`;
      })
      .filter(v => v !== null)
      .join('&');
  },
});

export const isHttpClientCancel = Axios.isCancel;
export const isHttpClientError = Axios.isAxiosError;

export const httpClientCancelTokenStatic = Axios.CancelToken;
export type THttpClientCancelTokenStatic = CancelTokenStatic;
export type THttpClientCancelToken = CancelToken;

export type TAsyncActionOptions = {
  cancelToken: {
    cancelToken?: THttpClientCancelToken;
  };
};

import styled, { css } from 'styled-components';

import { Wrapper } from '@trader/components';

export const Root = styled(Wrapper)`
  ${() => css`
    width: 100%;
    height: 100%;
    flex-direction: column;
    border-radius: 5px;

    .trading-tabs {
      padding-top: 6px;
    }
  `};
`;

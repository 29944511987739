import { FC, MouseEvent } from 'react';

import { MODAL_TYPES } from '@trader/constants';
import { TPositionMetricEntity, useMst } from '@trader/store';
import { ScrollableItemActions } from '@trader/components';

interface IPositionActions {
  position: TPositionMetricEntity;
}

export const PositionActions: FC<IPositionActions> = ({ position }) => {
  const store = useMst();

  const handleEditClick = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    store.ui.modal.open(MODAL_TYPES.addProtection, {
      symbol: position.symbol,
      positionId: position.positionId,
      side: position.side,
    });
  };

  const handleRemoveClick = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    store.ui.modal.open(MODAL_TYPES.closePosition, {
      symbol: position.symbol,
      positionId: position.positionId,
      side: position.side,
    });
  };

  return (
    <ScrollableItemActions
      onEditClick={handleEditClick}
      onRemoveClick={handleRemoveClick}
    />
  );
};

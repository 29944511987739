import { isEmpty } from 'lodash';

import { TInstrumentEntity, TTradingHoursModel } from '@trader/store';

import { getUTCDate } from '../dateUtils';

export const getInstrumentTradingHours = (
  sessions: TInstrumentEntity['sessions'],
  date: Date = getUTCDate()
): TTradingHoursModel[] => {
  if (isEmpty(sessions)) {
    return [];
  }

  const currentDayIndex = date.getDay();

  return sessions[currentDayIndex]?.tradingHours || [];
};

import { useContext, useEffect } from 'react';

import {
  HubConnection,
  EConnectionHub,
  EConnectionSubscription,
} from '@trader/services';
import { ESocketUpdateAction, IOrderMessage } from '@trader/types';
import { getAccountTypeForConnection, productId } from '@trader/constants';
import { useMst } from '@trader/store';
import { TradingNotificationsContext } from '@trader/contexts';

import { useStartConnection } from './core';

/**
 * Custom hook that handles the subscription to pending orders.
 * It processes incoming order messages, updates the store, and handles modal interactions.
 */
export const usePendingOrders = () => {
  const store = useMst();
  const { handleSaveMessage } = useContext(TradingNotificationsContext);

  const { connection, isAppDataLoaded } = useStartConnection(
    EConnectionHub.Account,
    EConnectionSubscription.PendingOrders,
    'account'
  );

  const idToken = store.auth.tokens.idToken || '';
  const activeTradingAccount = store.user.tradingAccount;
  const platformLogin = activeTradingAccount?.platformLogin || '';
  const accountType = activeTradingAccount?.accountType || '';
  const product = store.user.getAccountProduct();

  const handleSubscribe = async (hub: HubConnection) => {
    if (platformLogin && accountType) {
      await hub.send(
        'SubscribeOnOrders',
        platformLogin || null,
        productId[product],
        getAccountTypeForConnection[accountType]
      );
      hub.on('order', handleMessage);
    }
  };

  const handleMessage = (message: IOrderMessage) => {
    switch (message.updateAction) {
      case ESocketUpdateAction.Create:
        handleSaveMessage({ type: 'order', ...message });
        break;
      case ESocketUpdateAction.Delete:
        handleSaveMessage({ type: 'order', ...message });
        break;
      case ESocketUpdateAction.Update:
        handleSaveMessage({ type: 'order', ...message });
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    isAppDataLoaded && connection.subscribe(handleSubscribe);

    return () => {
      if (platformLogin && accountType) {
        connection.unsubscribe(async hub => {
          await hub?.send(
            'UnsubscribeFromOrders',
            platformLogin || null,
            productId[product],
            getAccountTypeForConnection[accountType]
          );
        });
      }
    };
  }, [platformLogin, idToken, isAppDataLoaded]);
};

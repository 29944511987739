import React, { FC } from 'react';
import { observer } from 'mobx-react-lite';

import {
  IScrollableListItemDetail,
  PurchaseType,
  ScrollableList,
  ScrollableListItem,
  SymbolWithIcon,
} from '@trader/components';
import { useI18next } from '@trader/services';
import { TOrderMetricEntity } from '@trader/store';
import { formatTableRowValueByPipSize } from '@trader/utils';
import { iconSizes } from '@trader/constants';

import { usePendingOrdersListMetrics } from './hooks/usePendingOrdersListMetrics';
import { PendingOrderActions } from './components/pendingOrderActions';
import { IPendingOrdersList } from './index';

export const MobilePendingOrdersList: React.FC<IPendingOrdersList> = observer(
  ({ symbol }) => {
    const { pendingOrdersMetrics } = usePendingOrdersListMetrics(symbol);

    return (
      <ScrollableList<TOrderMetricEntity>
        data={pendingOrdersMetrics}
        renderItem={po => <PendingOrdersListItem pendingOrder={po} />}
      />
    );
  }
);

const PendingOrdersListItem: FC<{ pendingOrder: TOrderMetricEntity }> =
  observer(({ pendingOrder }) => {
    const { translate } = useI18next();

    const detailsItems: IScrollableListItemDetail[] = [
      {
        id: 'side',
        header: translate('COMMON.LABELS.TYPE'),
        value: <PurchaseType value={pendingOrder.side as string} />,
      },
      {
        id: 'quantity',
        header: translate('COMMON.LABELS.VOLUME'),
        value: pendingOrder.quantity,
      },
      {
        id: 'stopLoss',
        header: translate('COMMON.LABELS.SL'),
        value: formatTableRowValueByPipSize(
          pendingOrder.stopLoss,
          pendingOrder.pipSize
        ),
      },
      {
        id: 'takeProfit',
        header: translate('COMMON.LABELS.TP'),
        value: formatTableRowValueByPipSize(
          pendingOrder.takeProfit,
          pendingOrder.pipSize
        ),
      },
      {
        id: 'limitPrice',
        header: translate('COMMON.LABELS.LIMIT_PRICE'),
        value: formatTableRowValueByPipSize(
          pendingOrder.limitPrice,
          pendingOrder.pipSize
        ),
      },
      {
        id: 'stopPrice',
        header: translate('COMMON.LABELS.STOP_PRICE'),
        value: formatTableRowValueByPipSize(
          pendingOrder.stopPrice,
          pendingOrder.pipSize
        ),
      },
    ];

    return (
      <ScrollableListItem
        leadingContent={
          <SymbolWithIcon
            symbol={pendingOrder.symbol}
            iconUrl={pendingOrder.iconUrl}
            iconSize={iconSizes.small}
            textVariant='medium'
          />
        }
        trailingContent={<PendingOrderActions pendingOrder={pendingOrder} />}
        detailsItems={detailsItems}
      />
    );
  });

import React, { useMemo } from 'react';
import { debounce } from 'lodash';
import { TableVirtuoso } from 'react-virtuoso';

import { useI18next } from '@trader/services';
import { Progress, Typography } from '@trader/components';

import * as Styled from './styled';

export interface IScrollableTableColumn<T extends object> {
  id: string;
  header: React.ReactNode | string;
  render?: (row: T, index: number) => React.ReactNode | string | number;
  minWidth?: number;
  headerStyle?: React.CSSProperties;
  cellStyle?: React.CSSProperties;
}

interface IScrollableTableProps<T extends object> {
  data: T[];
  columns: IScrollableTableColumn<T>[];
  overscan?: number;
  increaseViewportBy?: number;
  totalCount?: number;
  itemsPerPage?: number;
  emptyMessage?: string;
  keepHeaderOnEmptyMessage?: boolean;
  isLoading?: boolean;
  withTransparentHeader?: boolean;
  withBorder?: boolean;
  onLoadMore?: (pageNumber: number) => void;
}

const defaultOverscan = 10;
const debounceTime = 300;
export const ScrollableTable = <T extends object>({
  data,
  columns,
  overscan = defaultOverscan,
  increaseViewportBy = defaultOverscan,
  totalCount,
  itemsPerPage,
  emptyMessage,
  keepHeaderOnEmptyMessage,
  isLoading,
  withTransparentHeader = true,
  withBorder = false,
  onLoadMore = () => null,
}: IScrollableTableProps<T>) => {
  const { translate } = useI18next();

  const debouncedLoadMore = useMemo(
    () => debounce(onLoadMore, debounceTime),
    [onLoadMore]
  );

  if (isLoading) {
    return <Progress />;
  }

  if (!data || !data.length) {
    return (
      <>
        {keepHeaderOnEmptyMessage && (
          <Styled.Table $withBorder={withBorder}>
            <Styled.Header $withTransparentHeader={withTransparentHeader}>
              {columns.map(column => (
                <th
                  key={column.id}
                  style={{ ...column.headerStyle, width: column.minWidth }}
                >
                  {column.header}
                </th>
              ))}
            </Styled.Header>
          </Styled.Table>
        )}
        <Typography variant='medium' padding='12px' width='100%'>
          {emptyMessage || translate('COMMON.LABELS.NO_RECORDS_FOUND')}
        </Typography>
      </>
    );
  }

  const handleLoadMore = (lastItemIndex: number) => {
    if (itemsPerPage) {
      const pageNumber = Math.ceil(lastItemIndex / itemsPerPage) + 1;
      debouncedLoadMore(pageNumber);
    }
  };

  return (
    <TableVirtuoso
      data={data}
      totalCount={totalCount}
      fixedHeaderContent={() => (
        <Styled.Header $withTransparentHeader={withTransparentHeader}>
          {columns.map(column => (
            <th
              key={column.id}
              style={{ ...column.headerStyle, width: column.minWidth }}
            >
              {column.header}
            </th>
          ))}
        </Styled.Header>
      )}
      itemContent={index => {
        const row = data[index];
        return (
          <>
            {columns.map(column => (
              <Styled.Cell key={column.id} style={column.cellStyle}>
                {column.render ? column.render(row, index) : row[column.id]}
              </Styled.Cell>
            ))}
          </>
        );
      }}
      components={{
        Table: props => <Styled.Table {...props} $withBorder={withBorder} />,
      }}
      overscan={overscan}
      increaseViewportBy={increaseViewportBy}
      endReached={handleLoadMore}
      style={{ width: '100%' }}
    />
  );
};

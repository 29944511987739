import React, { useCallback, useEffect } from 'react';
import { useTheme } from '@mui/material';

import { adapterLineStyle, emptyFn, MODAL_TYPES } from '@trader/constants';
import { TOrderMetricEntity, useMst } from '@trader/store';
import { useI18next } from '@trader/services';

import {
  IChartingLibraryWidget,
  IOrderLineAdapter,
} from '../../charting_library';
import { useGetAdapterDiff } from '../muliBands/useGetAdapterDiff';
import { useDisplayOrderProtection } from './useDisplayOrderProtection';

interface IOrder {
  key: string;
  ref: IOrderLineAdapter | undefined;
}

const orderLineLength = 18;
const orders = new Map<string, IOrder>();

export const useDisplayAllOrders = (
  widget: React.MutableRefObject<IChartingLibraryWidget | null>
) => {
  const store = useMst();
  const theme = useTheme();
  const { translate } = useI18next();

  const { sideColor, typeColor } = useGetAdapterDiff('Order');

  const { createOrderProtection, updateOrderProtection, clearOrderProtection } =
    useDisplayOrderProtection(widget);

  const [activeChartSymbol, setActiveChartSymbol] = React.useState<
    string | undefined
  >(undefined);

  const idToken = store.auth.tokens.idToken;
  const trading = store.trading.getTrading('createOrder');

  const ordersMetrics =
    store.entities.ordersMetrics.getAll<TOrderMetricEntity>();

  const symbol =
    (trading.instrument?.symbol as string) ||
    store.pages.trading.getInstrumentSymbolByLayout();

  const shouldDisplayAllOrdersOnChart =
    !trading.side && store.ui.tradingView.shouldDisplayAllLineAdapters;

  const symbolOrdersMetrics = ordersMetrics.filter(
    metric => metric.symbol === symbol
  );

  const shouldRender =
    symbol.toUpperCase() === activeChartSymbol && shouldDisplayAllOrdersOnChart;

  const createOrder = (id: string) => {
    createOrderProtection(id);

    const metric = store.entities.ordersMetrics.get<TOrderMetricEntity>(id);

    const price = (metric?.limitPrice || metric?.stopPrice) as number;

    const ref = widget?.current
      ?.activeChart()
      .createOrderLine()
      .setCancelTooltip(translate('COMMON.LABELS.CANCEL_ORDER'))
      .setText(
        `${translate(
          `COMMON.LABELS.${metric.side?.toUpperCase()}`
        )} ${translate('TRADING.LIMIT')}`
      )
      .setBodyTextColor(sideColor(metric?.side))
      .setBodyBorderColor(typeColor(id, metric?.side))
      .setBodyBackgroundColor(theme.palette.white.main)
      .setQuantity(`${metric?.quantity}; ID: ${metric?.orderId}`)
      .setQuantityBackgroundColor(theme.palette.tab.light)
      .setQuantityBorderColor(theme.palette.tab.light)
      .setLineColor(typeColor(id, metric?.side))
      .setLineLength(orderLineLength)
      .setLineStyle(adapterLineStyle)
      .setCancelButtonBorderColor(theme.palette.tab.light)
      .setCancelButtonBackgroundColor(theme.palette.tab.light)
      .setCancelButtonIconColor(theme.palette.white.main)
      .onCancel(function () {
        store.ui.modal.open(MODAL_TYPES.cancelOrder, {
          symbol: metric?.symbol,
          orderId: metric?.orderId,
          side: metric?.side,
        });
      })
      .onModify(function () {
        store.ui.modal.open(MODAL_TYPES.editOrder, {
          symbol: metric.symbol,
          orderId: metric.orderId,
          side: metric.side,
        });
      })
      .setPrice(price);

    orders.set(id, {
      ...orders.get(id),
      key: id,
      ref,
    });
  };

  const clearOrders = useCallback(() => {
    Array.from(orders.values()).forEach(order => {
      order?.ref?.remove();
    });
    orders.clear();

    clearOrderProtection();
  }, []);

  // wait till chart will be fully ready to drawing adapters
  useEffect(() => {
    widget.current?.onChartReady(() => {
      widget.current
        ?.activeChart()
        .onDataLoaded()
        .subscribe(null, () => {
          setActiveChartSymbol(
            widget.current?.activeChart().symbol().toUpperCase()
          );
        });
    });
    return () => {
      setActiveChartSymbol(undefined);
    };
  }, [symbol]);

  // initialize orders
  useEffect(() => {
    if (shouldRender) {
      widget.current?.onChartReady(() => {
        const isDataReady = widget.current?.activeChart().dataReady(emptyFn);
        if (isDataReady) {
          symbolOrdersMetrics.forEach(metric => {
            if (!metric) {
              return;
            }
            if (!orders.get(metric?.orderId)) {
              createOrder(metric?.orderId);
            }
          });
        }
      });
    }
    return () => clearOrders();
  }, [activeChartSymbol, symbol, idToken]);

  // create & update orders
  useEffect(() => {
    if (shouldRender) {
      widget.current?.onChartReady(() => {
        const isDataReady = widget.current?.activeChart().dataReady(emptyFn);
        if (isDataReady) {
          symbolOrdersMetrics.forEach(metric => {
            const price = (metric?.limitPrice || metric?.stopPrice) as number;
            const orderRef = orders.get(metric?.orderId)?.ref;

            if (orderRef) {
              orderRef?.setPrice(price);
              orderRef?.setQuantity(
                `${metric.quantity}; ID: ${metric?.orderId}`
              );

              updateOrderProtection(metric.orderId);
            } else {
              createOrder(metric?.orderId);
            }
          });
        }
      });
    }
  }, [
    JSON.stringify(symbolOrdersMetrics),
    shouldDisplayAllOrdersOnChart,
    activeChartSymbol,
    symbol,
  ]);

  // delete order
  useEffect(() => {
    Array.from(orders.values()).forEach((order: IOrder) => {
      const matchedOrder = store.entities.ordersMetrics.get<TOrderMetricEntity>(
        order.key
      );
      if (!matchedOrder) {
        orders.get(order.key)?.ref?.remove();
        orders.delete(order.key);
        updateOrderProtection(order.key);
      }
    });
  }, [symbolOrdersMetrics.length]);

  useEffect(() => {
    if (!shouldDisplayAllOrdersOnChart) {
      clearOrders();
    }
  }, [shouldDisplayAllOrdersOnChart]);
};

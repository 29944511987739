import { useEffect } from 'react';

import {
  TPositionMetricEntity,
  TTradingAccountEntity,
  useMst,
} from '@trader/store';
import {
  EConnectionHub,
  EConnectionSubscription,
  HubConnection,
} from '@trader/services';
import { IMessage } from '@trader/types';
import { getSpreadDifference } from '@trader/utils';
import { getAccountTypeForConnection, productId } from '@trader/constants';

import { useStartConnection } from './core';

type TUpdatedPositions = {
  positions: { positionId: string; price: number }[];
  platformLogin: string;
};

interface IParams {
  hub: EConnectionHub;
  subscriber?: EConnectionSubscription;
  account: TTradingAccountEntity;
  onUpdate: (updatedPositions: TUpdatedPositions) => void;
}

export const usePositionsCurrentPrice = (params?: IParams) => {
  const store = useMst();

  const { connection, isAppDataLoaded } = useStartConnection(
    params?.hub || EConnectionHub.Positions,
    params?.subscriber || EConnectionSubscription.PositionsCurrentPrice,
    'quotes'
  );

  const positions =
    params?.account?.positions ||
    store.entities.positionsMetrics.getAll<TPositionMetricEntity>();

  const positionSymbols = positions.map(p => p.symbol);
  const uniqueSymbols = [...new Set(positionSymbols)];

  const handleSubscribe = async (hub: HubConnection) => {
    if (positions.length > 0 && isAuth && platformLogin && accountType) {
      await hub.send(
        'SubscribeOnQuotes',
        uniqueSymbols,
        1,
        productId[product],
        platformLogin,
        getAccountTypeForConnection[accountType]
      );
      hub.on('OnQuote', handleMessage);
    }
  };

  const idToken = store.auth.tokens.idToken;
  const isAuth = store.auth.isAuth;
  const activeTradingAccount = params?.account || store.user.tradingAccount;
  const platformLogin = activeTradingAccount?.platformLogin;
  const accountType = activeTradingAccount?.accountType;
  const product = store.user.getAccountProduct();

  function handleMessage(message: IMessage) {
    const symbolPositions = positions.filter(p => p.symbol === message.s);
    if (symbolPositions.length > 0) {
      const updatedPositions: TUpdatedPositions['positions'] = [];

      for (const position of symbolPositions) {
        const { ask, bid } = getSpreadDifference(
          position.spreadDiff,
          message.a,
          message.b,
          position.spreadDiffBalance,
          position.pipSize
        );
        const price = position.side === 'Buy' ? ask : bid;

        if (params?.onUpdate) {
          updatedPositions.push({
            positionId: position.positionId,
            price,
          });
        } else {
          store.entities.positionsMetrics.update(position.positionId, {
            price,
          });
        }
      }

      params?.onUpdate &&
        params?.onUpdate({
          positions: updatedPositions,
          platformLogin: activeTradingAccount?.platformLogin || '',
        });
    }
  }

  /**
   * Handle subscribe on positions symbols.
   */
  useEffect(() => {
    isAppDataLoaded && connection.subscribe(handleSubscribe);

    return () => {
      if (positions.length > 0 && isAuth && platformLogin && accountType) {
        connection.unsubscribe(async hub => {
          await hub?.send(
            'UnsubscribeFromAllQuotes',
            uniqueSymbols,
            1,
            productId[product],
            platformLogin,
            getAccountTypeForConnection[accountType]
          );
        });
      }
    };
  }, [positions.length, platformLogin, idToken, isAppDataLoaded]);
};

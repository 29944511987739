import { useEffect } from 'react';

import {
  EConnectionHub,
  EConnectionSubscription,
  HubConnection,
  useI18next,
} from '@trader/services';
import {
  getAccountTypeForConnection,
  productId,
  tradingCodeMessages,
  tradingCodes,
} from '@trader/constants';
import { useMst } from '@trader/store';
import { ITradeResultMessage } from '@trader/types';

import { useStartConnection } from './core';

/**
 * Custom hook that manages trade result updates by subscribing to a hub and handling incoming messages to update the store.
 */
export const useTradeResults = () => {
  const store = useMst();
  const { translate } = useI18next();
  const { connection } = useStartConnection(
    EConnectionHub.Account,
    EConnectionSubscription.TradeResults,
    'account'
  );

  const idToken = store.auth.tokens.idToken || '';
  const activeTradingAccount = store.user.tradingAccount;
  const platformLogin = activeTradingAccount?.platformLogin || '';
  const accountType = activeTradingAccount?.accountType || '';
  const product = store.user.getAccountProduct();

  const handleSubscribe = async (hub: HubConnection) => {
    if (platformLogin && accountType && product) {
      await hub.send(
        'SubscribeOnTrade',
        platformLogin || null,
        productId[product],
        getAccountTypeForConnection[accountType]
      );
      hub.on('trade', handleMessage);
    }
  };

  const handleMessage = (message: ITradeResultMessage) => {
    const code = message.retCode?.toString();
    if (tradingCodes.error.includes(code)) {
      store.ui.modal.close();

      const errorMessageKey = tradingCodeMessages.error[code]
        ? tradingCodeMessages.error[code]
        : 'WARNINGS.TRADING_ACTION_FAILED';

      store.notifications.add({
        message: translate(errorMessageKey),
        options: {
          variant: 'warning',
        },
      });
    }
  };

  useEffect(() => {
    connection.subscribe(handleSubscribe);

    return () => {
      if (platformLogin && accountType && product) {
        connection.unsubscribe(async hub => {
          await hub?.send(
            'UnsubscribeFromTrade',
            platformLogin || null,
            productId[product],
            getAccountTypeForConnection[accountType]
          );
        });
      }
    };
  }, [platformLogin, idToken]);
};

import { FC } from 'react';
import { Stack } from '@mui/material';
import { observer } from 'mobx-react-lite';

import { IInstrumentDetailsHeader } from '@trader/containers';
import { getInstrumentDifference } from '@trader/utils';
import { ActionButtons, Wrapper } from '@trader/components';
import { defaultIcon } from '@trader/constants';

import { MarketStatusIcon } from './components/marketStatusIcon';
import { FavouriteIconButton } from './components/favoriteIconButton';
import { PriceAlertIconButton } from './components/priceAlertIconButton';
import * as Styled from './styled';

export const MobileInstrumentDetailsHeader: FC<IInstrumentDetailsHeader> =
  observer(({ instrument }) => {
    if (!instrument?.symbol) {
      return null;
    }

    const { isGrow, openCloseDifferenceInPercent, openCloseDifference } =
      getInstrumentDifference(
        instrument.ask,
        instrument.close,
        instrument.pipSize
      );

    return (
      <Styled.MobileHeader>
        <Wrapper width='100%' justifyContent='space-between' marginBottom='8px'>
          <Wrapper alignItems='center'>
            <img
              src={instrument.iconUrl || defaultIcon}
              alt='instrument icon'
              width='30px'
              height='30px'
            />
            <Styled.Symbol marginRight='8px'>{instrument.symbol}</Styled.Symbol>
            <Styled.Difference $isAboveZero={isGrow}>
              {openCloseDifference} ({openCloseDifferenceInPercent}%)
            </Styled.Difference>
          </Wrapper>
          <Stack direction='row' spacing='12px'>
            <MarketStatusIcon
              isUnavailable={instrument.tradingAvailability.isUnavailable}
              openIn={instrument.tradingAvailability.openIn}
            />
            <FavouriteIconButton instrument={instrument} />
            <PriceAlertIconButton symbol={instrument.symbol} />
          </Stack>
        </Wrapper>
        <ActionButtons
          symbol={instrument.symbol}
          ask={instrument.ask}
          bid={instrument.bid}
          pipSize={instrument.pipSize}
        />
      </Styled.MobileHeader>
    );
  });

import { useEffect } from 'react';
import { Stack } from '@mui/material';
import { observer } from 'mobx-react-lite';

import {
  betaMaxSideBarWidth,
  betaMinimizedSideBarWidth,
  defaultCategories,
  shouldDisplayChangeColumnOnInstrumentList,
  shouldDisplaySpreadColumnOnInstrumentList,
} from '@trader/constants';
import {
  ExpandedContent,
  IconButton,
  Popover,
  SearchInstrument,
  Select,
  Subcategories,
  Switcher,
  Wrapper,
} from '@trader/components';
import { ELeftBarColumnNames, TCategoryEntity, useMst } from '@trader/store';
import { useI18next } from '@trader/services';
import { useResponsiveDesign } from '@trader/hooks';

import { InstrumentsTable } from './components/instrumentsTable';
import * as Styled from './desktop.styled';

export const DesktopLeftBar = observer(() => {
  const store = useMst();

  const { leftBar } = useResponsiveDesign();

  const selectedCategoryName =
    store.pages.trading.selectedCategory?.name || defaultCategories[0].name;
  const fetchedCategories = store.entities.categories.getAll<TCategoryEntity>();
  const categoryOptions = [
    ...(fetchedCategories.length === 0 ? defaultCategories : fetchedCategories),
  ].map(category => ({
    title: category.name,
    value: category.name,
  }));

  useEffect(() => {
    if (leftBar.shouldMinimizedWidth) {
      store.ui.sidebar.updateLeftBarWidth(betaMinimizedSideBarWidth);
    } else {
      store.ui.sidebar.updateLeftBarWidth(betaMaxSideBarWidth);
    }
  }, [leftBar.shouldMinimizedWidth]);

  const handleCategoryChange = (categoryName: string) => {
    store.pages.trading.selectCategoryAsync.run(categoryName);
  };

  if (store.ui.sidebar.leftBar.isShrinked) {
    return (
      <Styled.Shrinked>
        <IconButton
          iconType='arrowRight'
          onClick={() => {
            store.ui.sidebar.updateLeftBarWidth(
              leftBar.shouldMinimizedWidth
                ? betaMinimizedSideBarWidth
                : betaMaxSideBarWidth
            );
            store.ui.sidebar.expandLeftBar();
          }}
        />
      </Styled.Shrinked>
    );
  }

  return (
    <ExpandedContent
      width={store.ui.sidebar.leftBar.width}
      minWidth={100}
      maxWidth={betaMaxSideBarWidth}
      disabled={leftBar.shouldDisabledChangingWidth}
      onUpdateWidth={newWidth => store.ui.sidebar.updateLeftBarWidth(newWidth)}
    >
      <SearchInstrument />
      <Wrapper padding='4px 8px' justifyContent='flex-end' alignItems='center'>
        {!leftBar.shouldHideColumnSelection && (
          <Popover
            behavior='click'
            elevation={4}
            transformOrigin={{
              vertical: 0,
              horizontal: 180,
            }}
            trigger={<IconButton iconType='configurationMenu' />}
            content={<TableConfiguration />}
          />
        )}
        <IconButton
          iconType='shrink'
          onClick={store.ui.sidebar.shrinkLeftBar}
        />
      </Wrapper>
      <Styled.Selection>
        <Select
          options={categoryOptions}
          value={categoryOptions.filter(o => o.value === selectedCategoryName)}
          onChange={event => handleCategoryChange(event.target.value[0].value)}
          sx={{
            '& .MuiSelect-select': {
              padding: '5.5px 32px 5.5px 14px',
            },
          }}
        />
        <Subcategories withTopDivider={false} />
      </Styled.Selection>
      <InstrumentsTable />
    </ExpandedContent>
  );
});

const TableConfiguration = observer(() => {
  const { translate } = useI18next();
  const store = useMst();

  const getColumns = () => {
    const columns: { label: string; name: ELeftBarColumnNames }[] = [
      {
        label: translate('COMMON.LABELS.SELL'),
        name: ELeftBarColumnNames.Sell,
      },
      { label: translate('COMMON.LABELS.BUY'), name: ELeftBarColumnNames.Buy },
      {
        label: translate('COMMON.LABELS.24HTREND'),
        name: ELeftBarColumnNames.DailyTrend,
      },
      {
        label: translate('COMMON.LABELS.FAVOURITE'),
        name: ELeftBarColumnNames.Favourite,
      },
    ];

    if (shouldDisplaySpreadColumnOnInstrumentList) {
      columns.splice(2, 0, {
        label: translate('COMMON.LABELS.SPREAD'),
        name: ELeftBarColumnNames.Spread,
      });
    }

    if (shouldDisplayChangeColumnOnInstrumentList) {
      columns.splice(2, 0, {
        label: translate('COMMON.LABELS.CHANGE'),
        name: ELeftBarColumnNames.Change,
      });
    }

    return columns;
  };

  return (
    <Styled.TableConfiguration>
      <Styled.TableConfigurationTitle>
        {translate('COMMON.LABELS.COLUMNS')}
      </Styled.TableConfigurationTitle>
      <Stack padding='12px 14px' spacing='8px' direction='column'>
        {getColumns().map(c => (
          <Switcher
            key={c.label}
            name={c.name}
            value={store.ui.sidebar.leftBar.includedColumns[c.name]}
            onChange={() => store.ui.sidebar.toggleLeftBarColumn(c.name)}
            label={c.label}
            shouldHideControlInfo
          />
        ))}
      </Stack>
    </Styled.TableConfiguration>
  );
});

import React, { useCallback, useEffect } from 'react';
import { useTheme } from '@mui/material';

import { useMst } from '@trader/store';
import { useI18next } from '@trader/services';
import { adapterLineStyle, emptyFn } from '@trader/constants';

import {
  IChartingLibraryWidget,
  IOrderLineAdapter,
} from '../../charting_library';

const tpLineLength = 5;
let tpLineAdapter: IOrderLineAdapter | undefined = undefined;

export const useDisplayTakeProfit = (
  widget: React.MutableRefObject<IChartingLibraryWidget | null>
) => {
  const store = useMst();
  const theme = useTheme();
  const { translate } = useI18next();

  const idToken = store.auth.tokens.idToken;

  const trading = store.trading.getTrading('createOrder');

  const symbol = trading.instrument?.symbol as string;

  const shouldDisplayTakeProfit = !!trading.side && trading.isTakeProfit;

  const createOrder = (price: string) => {
    tpLineAdapter = widget?.current
      ?.activeChart()
      .createOrderLine()
      .setText(`${translate('COMMON.LABELS.TAKE_PROFIT')} ${trading.profit}`)
      .setBodyTextColor(theme.palette.white.main)
      .setBodyBorderColor(theme.palette.black.light)
      .setBodyBackgroundColor(theme.palette.black.light)
      .setLineColor(theme.palette.black.light)
      .setCancelButtonIconColor(theme.palette.white.main)
      .setCancelButtonBorderColor(theme.palette.black.light)
      .setCancelButtonBackgroundColor(theme.palette.black.light)
      .setLineLength(tpLineLength)
      .setLineStyle(adapterLineStyle)
      .setQuantity('')
      .onMoving(function () {
        trading.updatePriceFromChart(
          this.getPrice().toString(),
          'chartTakeProfit'
        );
      })
      .onMove(function () {
        trading.updatePriceFromChart(
          this.getPrice().toString(),
          'chartTakeProfit'
        );
      })
      .onCancel(function () {
        trading.runInAction(() => {
          trading.isTakeProfit = false;
        });
      })
      .setPrice(Number(price));
  };

  const clearTP = useCallback(() => {
    tpLineAdapter?.remove();
    tpLineAdapter = undefined;
  }, []);

  // initialize TP order
  useEffect(() => {
    if (shouldDisplayTakeProfit) {
      widget.current?.onChartReady(() => {
        const isDataReady = widget.current?.activeChart().dataReady(emptyFn);
        if (isDataReady) {
          createOrder(trading.price);
        }
      });
    }
    return () => clearTP();
  }, [symbol, shouldDisplayTakeProfit, idToken]);

  // update take profit
  useEffect(() => {
    if (shouldDisplayTakeProfit) {
      widget.current?.onChartReady(() => {
        const isDataReady = widget.current?.activeChart().dataReady(emptyFn);
        if (isDataReady && tpLineAdapter) {
          tpLineAdapter.setPrice(
            trading.TPEditMode === 'Chart'
              ? Number(trading.chartTakeProfit)
              : Number(trading.takeProfit)
          );
          tpLineAdapter.setText(
            `${translate('COMMON.LABELS.TAKE_PROFIT')} ${trading.profit}`
          );
        }
      });
    }
  }, [
    shouldDisplayTakeProfit,
    trading.takeProfit,
    trading.chartTakeProfit,
    trading.profit,
    trading.TPEditMode,
  ]);

  useEffect(() => {
    if (!shouldDisplayTakeProfit) {
      clearTP();
    }
  }, [shouldDisplayTakeProfit]);
};

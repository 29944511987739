import { TAlertEntity, useMst } from '@trader/store';
import { useEffect } from 'react';

export const useAlertsListData = () => {
  const store = useMst();
  const alerts = store.entities.alerts.getAll<TAlertEntity>();

  useEffect(() => {
    store.entities.alerts.getAlertsAsync.run();
  }, []);

  return { alerts };
};

import { useEffect } from 'react';

import {
  EOrderSide,
  EStripName,
  IBackTestingMultiplierFinished,
  IBackTestingSummary,
} from '@trader/types';
import { TPositionMetricEntity, useMst } from '@trader/store';
import { EConnectionHub, EConnectionSubscription } from '@trader/services';

import { useStartConnection } from './core';

type TMessageType =
  | 'OrderCreated'
  | 'PositionCreated'
  | 'StrategyInitialized'
  | 'StrategyFinished'
  | 'StrategyMultiplierFinished'
  | 'TradeResult';

interface IMessage {
  name: TMessageType;
  id: number;
  side: EOrderSide;
  bandId: string;
  retCode: number;
}

export const useMuliBands = () => {
  const store = useMst();
  const { connection } = useStartConnection(
    EConnectionHub.MuliBands,
    EConnectionSubscription.MuliBands,
    'strategy'
  );

  const muliBands = store.pages.muliBands;
  const idToken = store.auth.tokens.idToken || '';

  const handleMessage = (
    message: IMessage | IBackTestingMultiplierFinished | IBackTestingSummary
  ) => {
    if (message.name === 'OrderCreated') {
      store.pages.muliBands.updateStripOrder(
        EStripName[message.bandId],
        message.id,
        EOrderSide[message.side]
      );
    }

    if (message.name === 'PositionCreated') {
      store.entities.positionsMetrics.getPositionsMetricsAsync.run();
      const position =
        store.entities.positionsMetrics.get<TPositionMetricEntity>(message.id);
      store.pages.muliBands.updateStripPosition(
        EStripName[message.bandId],
        message.id,
        position?.openPrice,
        position?.pl
      );
      store.pages.muliBands.runInAction(() => {
        store.pages.muliBands.firstOpenedPositionTime = new Date().toString();
      });
    }

    if (message.name === 'StrategyInitialized') {
      store.pages.muliBands.getExistingDataAsync.run(
        store.pages.muliBands.symbol ||
          store.pages.trading.getInstrumentSymbolByLayout()
      );
    }

    if (message.name === 'StrategyMultiplierFinished') {
      store.pages.muliBands.backTesting.addMultiplier(
        message as IBackTestingMultiplierFinished
      );
    }

    if (message.name === 'StrategyFinished') {
      const msg = message as IBackTestingSummary;
      !msg.isPartially && store.pages.muliBands.backTesting.setSummary(msg);
    }
  };

  useEffect(() => {
    connection.subscribe(hub => {
      hub.on('event', handleMessage);
    });
  }, [muliBands.id, idToken]);
};

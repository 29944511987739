import { getIconBaseProps } from './utils';

export const positions = {
  ...getIconBaseProps('positions'),
  width: '28px',
  height: '28px',
  viewBox: '0 0 28 28',
  fill: 'none',
  xmlns: 'http://www.w3.org/2000/svg',
  body: (
    <>
      <path
        d='M3 14H6.05556M6.05556 14C6.05556 18.3876 9.6124 21.9444 14 21.9444M6.05556 14C6.05556 9.6124 9.6124 6.05556 14 6.05556M21.9444 14H25M21.9444 14C21.9444 18.3876 18.3876 21.9444 14 21.9444M21.9444 14C21.9444 9.6124 18.3876 6.05556 14 6.05556M14 3V6.05556M14 21.9444V25M18.2778 14C18.2778 16.3626 16.3626 18.2778 14 18.2778C11.6374 18.2778 9.72222 16.3626 9.72222 14C9.72222 11.6374 11.6374 9.72222 14 9.72222C16.3626 9.72222 18.2778 11.6374 18.2778 14Z'
        stroke='currentColor'
        strokeWidth='1.2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </>
  ),
};

import { FC, ReactNode, useState } from 'react';
import { Collapse } from '@mui/material';
import { observer } from 'mobx-react-lite';

import { IconButton, Typography, Wrapper } from '@trader/components';
import { useI18next } from '@trader/services';

import * as Styled from './styled';

export interface IScrollableListItemDetail {
  id: string;
  header: string;
  value: string | ReactNode;
}

interface IScrollableListItem {
  leadingContent: ReactNode;
  trailingContent: ReactNode;
  detailsItems: IScrollableListItemDetail[];
  underDetailsContent?: ReactNode;
}

export const ScrollableListItem: FC<IScrollableListItem> = observer(
  ({ leadingContent, trailingContent, detailsItems, underDetailsContent }) => {
    const { translate } = useI18next();
    const [isDetailsOpen, setIsDetailsOpen] = useState(false);

    const handleDetailsToggle = () => {
      setIsDetailsOpen(prev => !prev);
    };

    return (
      <>
        <Styled.Content onClick={handleDetailsToggle}>
          {leadingContent}
          <Wrapper alignItems='center'>
            {trailingContent}
            <IconButton
              className='expandable-icon-button'
              iconType={isDetailsOpen ? 'arrowUp' : 'arrowDown'}
            />
          </Wrapper>
        </Styled.Content>
        <Collapse in={isDetailsOpen}>
          <Styled.Details>
            <Typography fontSize='large' fontWeight='bold' marginBottom='6px'>
              {translate('COMMON.LABELS.DETAILS')}
            </Typography>
            {detailsItems.map(detail => (
              <Styled.DetailsItem key={detail.id}>
                <Typography fontSize='medium'>{detail.header}</Typography>
                <Typography fontSize='medium'>{detail.value}</Typography>
              </Styled.DetailsItem>
            ))}
            {underDetailsContent}
          </Styled.Details>
        </Collapse>
      </>
    );
  }
);

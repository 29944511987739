import { useEffect, useMemo } from 'react';

import { TOrderMetricEntity, useMst } from '@trader/store';

export const usePendingOrdersListMetrics = (symbol?: string) => {
  const store = useMst();
  const orderMetrics: TOrderMetricEntity[] =
    store.entities.ordersMetrics.getAll();

  const pendingOrdersMetrics: TOrderMetricEntity[] = useMemo(() => {
    return symbol
      ? orderMetrics.filter(p => p.symbol === symbol)
      : orderMetrics;
  }, [symbol, orderMetrics]);

  useEffect(() => {
    store.entities.ordersMetrics.getOrdersMetricsAsync.run({ symbol });
  }, [symbol]);

  return { pendingOrdersMetrics };
};

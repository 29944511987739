import Smartlook from 'smartlook-client';
import { IIdentifyProps, ITrackProps } from './types';

const isProduction = import.meta.env.VITE_ENVIRONMENT === 'production';
export const SmartLookService = {
  init: () => {
    isProduction &&
      import.meta.env.VITE_SMARTLOOK_KEY &&
      Smartlook.init(import.meta.env.VITE_SMARTLOOK_KEY, {
        advancedNetwork: {
          allowedUrls: ['https://mobileapigateway.api.tplus.io'],
          allowedHeaders: ['Brand', 'Product'],
          websockets: true,
        },
        interceptors: {
          error: (data, _context) => {
            if (data.message?.startsWith('ResizeObserver loop')) {
              return false;
            }
            if (data.message?.includes('Window origin changed')) {
              return false;
            }
            if (data.message?.includes('Could not start new record')) {
              return false;
            }
            if (data.message?.includes('Timeout')) {
              return false;
            }
          },
        },
        region: 'eu',
      });
    Smartlook.initialized() &&
      Smartlook.record({
        forms: true,
        ips: true,
        numbers: true,
        emails: true,
        api: false,
      });
  },
  identify: ({ userId, email, name }: IIdentifyProps) => {
    Smartlook.initialized() &&
      Smartlook.identify(userId, {
        name,
        email,
      });
  },
  track: (eventName: string, props?: ITrackProps) => {
    Smartlook.initialized() && Smartlook.track(eventName, props || {});
  },
  pause: () => {
    Smartlook.initialized() && Smartlook.pause();
  },
  resume: () => {
    Smartlook.initialized() && Smartlook.resume();
  },
  ending: () => Smartlook.initialized() && Smartlook.anonymize(),
};

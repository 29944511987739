import React, { useMemo } from 'react';
import { observer } from 'mobx-react-lite';

import { IScrollableTableColumn, ScrollableTable } from '@trader/components';
import { useI18next } from '@trader/services';
import { MODAL_TYPES } from '@trader/constants';
import { TOrderMetricEntity, useMst } from '@trader/store';
import { useExtendItemWidthDependsLng } from '@trader/hooks';
import { formatTableRowValueByPipSize } from '@trader/utils';

import { PurchaseType, SymbolWithIcon } from '../scrollingList';
import { PendingOrderActions } from './components/pendingOrderActions';
import { usePendingOrdersListMetrics } from './hooks/usePendingOrdersListMetrics';
import { IPendingOrdersList } from './index';
import * as Styled from './desktop.styled';

export const DesktopPendingOrdersList: React.FC<IPendingOrdersList> = observer(
  ({ symbol }) => {
    const store = useMst();
    const { translate } = useI18next();
    const { getExtendedWidth } = useExtendItemWidthDependsLng();
    const { pendingOrdersMetrics } = usePendingOrdersListMetrics(symbol);

    const columns: IScrollableTableColumn<TOrderMetricEntity>[] = useMemo(
      () => [
        {
          id: 'symbol',
          header: translate('COMMON.LABELS.SYMBOL'),
          minWidth: 115,
          cellStyle: { paddingLeft: '15px' },
          headerStyle: { textAlign: 'left', paddingLeft: '20px' },
          render: row => (
            <SymbolWithIcon symbol={row.symbol} iconUrl={row.iconUrl} />
          ),
        },
        {
          id: 'side',
          header: translate('COMMON.LABELS.TYPE'),
          minWidth: getExtendedWidth('60', '2', ['nl']),
          render: row => <PurchaseType value={row.side as string} />,
        },
        { id: 'quantity', header: translate('COMMON.LABELS.VOLUME') },
        {
          id: 'stopLoss',
          header: translate('COMMON.LABELS.SL'),
          minWidth: 90,
          render: row =>
            formatTableRowValueByPipSize(row.stopLoss, row.pipSize),
        },
        {
          id: 'takeProfit',
          header: translate('COMMON.LABELS.TP'),
          minWidth: 90,
          render: row =>
            formatTableRowValueByPipSize(row.takeProfit, row.pipSize),
        },
        {
          id: 'limitPrice',
          header: translate('COMMON.LABELS.LIMIT_PRICE'),
          minWidth: 120,
          render: row =>
            formatTableRowValueByPipSize(row.limitPrice, row.pipSize),
        },
        {
          id: 'stopPrice',
          header: translate('COMMON.LABELS.STOP_PRICE'),
          minWidth: 120,
          render: row =>
            formatTableRowValueByPipSize(row.stopPrice, row.pipSize),
        },
        {
          id: 'actions',
          header: pendingOrdersMetrics.length ? (
            <Styled.CloseAllHeader
              onClick={() => {
                store.ui.modal.open(MODAL_TYPES.removeAllMetrics, {
                  shouldHideCloseButton: true,
                  removingType: 'orders',
                });
              }}
            >
              {translate('COMMON.LABELS.CLOSE_ALL')}
            </Styled.CloseAllHeader>
          ) : (
            ''
          ),
          minWidth: getExtendedWidth('80', '1.35', ['sk']),
          cellStyle: { paddingLeft: '15px' },
          headerStyle: { textAlign: 'right', paddingRight: '20px' },
          render: row => <PendingOrderActions pendingOrder={row} />,
        },
      ],
      [pendingOrdersMetrics.length]
    );

    return (
      <Styled.Root>
        <ScrollableTable<TOrderMetricEntity>
          data={pendingOrdersMetrics}
          columns={columns}
        />
      </Styled.Root>
    );
  }
);

import styled, { css } from 'styled-components';

export const Root = styled('div')`
  ${({ theme }) => css`
    display: flex;
    align-items: end;
    justify-content: end;
    padding-right: 15px;

    > button {
      padding: 7px;
    }

    .icon.edit path {
      stroke: ${theme.palette.gray.dark} !important;
    }

    .icon.remove path {
      stroke: ${theme.palette.red.light} !important;
    }

    ${theme.breakpoints.down('sm')} {
      align-items: center;
      padding-right: 0;

      .icon.edit {
        width: 14px;
        height: 14px;
      }

      .icon.remove {
        width: 12px;
        height: 12px;
      }

      > button {
        padding: 6px 9px;
      }
    }
  `};
`;

import styled, { css } from 'styled-components';

import { conditionApply } from '@trader/utils';

import { Wrapper } from '../wrapper';
import { Typography } from '../typography';

interface IRoot {
  $isShowDetails: boolean;
  $shouldIncreaseWidth?: boolean;
}

export const Root = styled(Wrapper)<IRoot>`
  ${({ $isShowDetails, $shouldIncreaseWidth, theme }) => css`
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    width: ${$shouldIncreaseWidth ? '570px' : '550px'};
    border-radius: 8px;
    margin-left: 16px;
    margin-right: -16px;
    display: none;
    transition: all 0.4s ease-in;

    ${conditionApply(
      [$isShowDetails],
      css`
        display: flex;
      `
    )}

    ${theme.breakpoints.down('sm')} {
      overflow: auto;
      width: 100%;
      margin-left: 0;
    }
  `};
`;

export const BalanceContainer = styled(Wrapper)<{ $width?: number }>`
  ${({ $width }) => css`
    min-width: ${$width ? `${$width}px` : '60px'};
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-right: 8px;
  `};
`;

export const Title = styled(Typography)`
  ${({ theme }) => css`
    font-size: ${theme.typography.tiny.fontSize};
    text-align: left;
    width: 100%;

    ${theme.breakpoints.down('sm')} {
      color: ${theme.palette.common.white};
    }
  `};
`;

export const Value = styled(Typography)`
  ${({ theme }) => css`
    font-size: ${theme.typography.medium.fontSize};
    font-weight: ${theme.typography.fontWeightMedium};
    text-align: left;

    ${theme.breakpoints.down('xl')} {
      font-size: ${theme.typography.default.fontSize};
    }

    ${theme.breakpoints.down('sm')} {
      color: ${theme.palette.common.white};
    }
  `};
`;

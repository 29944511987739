import { FC } from 'react';

import { TAlertEntity } from '@trader/store';
import { PurchaseType } from '@trader/components';

interface IAlertSide {
  alert: TAlertEntity;
}

export const AlertSide: FC<IAlertSide> = ({ alert }) => {
  const indicatorType = alert.conditions[0]?.leftExpression?.indicatorType;
  const side =
    indicatorType === 'Ask' ? 'Sell' : indicatorType === 'Bid' ? 'Buy' : '';

  if (!side) {
    return '--';
  }

  return <PurchaseType value={side} />;
};

import { HubConnection } from '@microsoft/signalr';
import { BaseVariant } from 'notistack';

export type TSubscribe = (connection: HubConnection) => Promise<void> | void;

export interface IParams {
  url: string;
  hub: EConnectionHub;
  subscription: EConnectionSubscription;
  refreshToken: () => Promise<void> | void;
  logout: () => void;
}

export enum EConnectionHub {
  Account = 'account',
  Quotes = 'quotes',
  Positions = 'positions',
  PriceAlerts = 'priceAlerts',
  TradingAccount = 'tradingAccount',
  TradingView = 'tradingView',
  Layouts = 'layouts',
  Inboxes = 'inboxes',
  MuliBands = 'strategy',
  Auth = 'auth',
}

export enum EConnectionSubscription {
  TradingView = 'TradingView',
  Watchlist = 'Watchlist',
  Layouts = 'Layouts',
  Instrument = 'Instrument',
  TradingAccount = 'TradingAccount',
  TradeResults = 'TradeResults',
  Positions = 'Positions',
  PendingOrders = 'PendingOrders',
  SignalPurchase = 'SignalPurchase',
  PriceAlertsCurrentPrice = 'PriceAlertsCurrentPrice',
  PriceAlertsCreation = 'PriceAlertsCreation',
  PositionsCurrentPrice = 'PositionsCurrentPrice',
  PositionsConversionRate = 'PositionsConversionRate',
  TradingAccountPositionsCurrentPrice = 'TradingAccountPositionsCurrentPrice',
  TradingAccountPositionsConversionRate = 'TradingAccountPositionsConversionRate',
  MuliBands = 'MuliBands',
  Auth = 'Auth',
  Challenges = 'Challenges',
  CustomerAccount = 'CustomerAccount',
}

export interface ISubscription {
  key: EConnectionSubscription;
  start: TSubscribe;
  status: 'subscribed' | 'unsubscribed';
}

export interface IConnectionMap {
  key: EConnectionHub;
  hub: null | HubConnection;
  subscriptions: Map<EConnectionSubscription, ISubscription>;
}

export type TConnectionCache = Map<EConnectionHub, IConnectionMap>;

interface IRenderSnackBarParams {
  msg: string;
  variant: BaseVariant;
  autoHide?: number | null;
}

export type TRenderSnackBar = (params: IRenderSnackBarParams) => void;

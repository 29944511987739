import { useEffect, useMemo } from 'react';

import {
  TPositionMetricEntity,
  TTradingAccountEntity,
  useMst,
} from '@trader/store';

export const usePositionsListMetrics = (symbol?: string) => {
  const store = useMst();
  const positions =
    store.entities.positionsMetrics.getAll<TPositionMetricEntity>();
  const accounts =
    store.entities.tradingAccounts.getAll<TTradingAccountEntity>();

  const positionsMetrics: TPositionMetricEntity[] = useMemo(() => {
    return symbol ? positions.filter(p => p.symbol === symbol) : positions;
  }, [symbol, positions]);

  useEffect(() => {
    accounts.length &&
      store.entities.positionsMetrics.getPositionsMetricsAsync.run();
  }, [accounts.length]);

  return { positionsMetrics };
};

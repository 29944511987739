import { Instance, types } from 'mobx-state-tree';

export const portfolioModel = types.model('portfolioModel', {
  equity: types.number,
  balance: types.number,
  startDailyPl: types.number,
  dailyPl: types.number,
  profit: types.number,
  freeMargin: types.number,
  usedMargin: types.number,
  availableForWithdrawal: types.number,
  marginLevel: types.number,
  maintenanceMargin: types.number,
  credit: types.number,

  // FE fields only.
  assets: types.optional(types.number, 0),
});

export const initialPortfolio = {
  equity: 0,
  balance: 0,
  startDailyPl: 0,
  dailyPl: 0,
  profit: 0,
  freeMargin: 0,
  usedMargin: 0,
  availableForWithdrawal: 0,
  marginLevel: 0,
  maintenanceMargin: 0,
  credit: 0,
  assets: 0,
};

export const portfolio = types.optional(portfolioModel, initialPortfolio);
export type TTradingAccountPortfolio = Instance<typeof portfolioModel>;

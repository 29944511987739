import React, { useEffect, useMemo, useState } from 'react';
import { observer } from 'mobx-react-lite';

import {
  formatDate,
  fixToTwoDigitAfterDot,
  formatByPipSize,
  formatTableRowValueByPipSize,
} from '@trader/utils';
import {
  PurchaseType,
  ChangePercentage,
  ProfitValue,
  TableColumnDateFilter,
  ScrollableTable,
  IScrollableTableColumn,
} from '@trader/components';
import { TPositionHistoryEntity, useMst } from '@trader/store';
import { TPlaceOrderSide } from '@trader/types';
import { useExtendItemWidthDependsLng } from '@trader/hooks';
import { IPositionHistoryBE } from '@trader/api';
import { useI18next } from '@trader/services';

import * as Styled from './styled';

interface ITotalState {
  totalCount: number;
  totalPl: number;
}

interface IPositionsHistory {
  shouldShowFooter?: boolean;
}

const ITEMS_PER_PAGE = 60;

export const PositionsHistory: React.FC<IPositionsHistory> = observer(
  ({ shouldShowFooter }) => {
    const [totalData, setTotalData] = useState<ITotalState>({
      totalCount: 0,
      totalPl: 0,
    });

    const { currentLng, translate } = useI18next();
    const { getExtendedWidth } = useExtendItemWidthDependsLng();

    const store = useMst();
    const accountCurrencySymbol = store.user.activeTradingAcc().currencySymbol;
    const positionsHistory =
      store.entities.positionsHistory.getAll() as TPositionHistoryEntity[];
    const formattedPositionHistory: TPositionHistoryEntity[] = useMemo(() => {
      return positionsHistory
        .map(item => {
          if (item.pipSize !== null) {
            return {
              ...item,
              entryPrice: +formatByPipSize(item.entryPrice, item.pipSize),
              exitPrice: +formatByPipSize(item.exitPrice, item.pipSize),
            };
          }
          return item;
        })
        .sort((a, b) => {
          return new Date(a.closeTime).getTime() >
            new Date(b.closeTime).getTime()
            ? -1
            : 1;
        });
    }, [positionsHistory]);
    const isFilterApplied = store.filters.isPositionHistoryFilterApplied();

    useEffect(() => {
      loadPositionsHistory(1);
    }, [
      store.filters.positionsHistory.date.completedTo,
      store.filters.positionsHistory.date.completedFrom,
      store.filters.positionsHistory.date.openedFrom,
      store.filters.positionsHistory.date.openedTo,
    ]);

    const loadPositionsHistory = async (pageNumber: number) => {
      const resp =
        await store.entities.positionsHistory.getPositionsHistoryAsync.run({
          pageSize: ITEMS_PER_PAGE,
          pageNumber: pageNumber,
          completedFrom: store.filters.positionsHistory.date.completedFrom,
          completedTo: store.filters.positionsHistory.date.completedTo,
          openedFrom: store.filters.positionsHistory.date.openedFrom,
          openedTo: store.filters.positionsHistory.date.openedTo,
          shouldClearBeforeMerge: pageNumber === 1,
        });
      if (resp && (!totalData.totalPl || !totalData.totalCount)) {
        setTotalData({
          totalPl: resp?.pl,
          totalCount: resp?.totalCount,
        });
      }
    };

    const renderFooter = () => {
      return (
        <Styled.Footer>
          <p>
            {translate('COMMON.LABELS.TOTAL_PL')}:
            <span>
              {accountCurrencySymbol}
              {fixToTwoDigitAfterDot(totalData.totalPl)}
            </span>
          </p>
        </Styled.Footer>
      );
    };

    const columns: IScrollableTableColumn<IPositionHistoryBE>[] = useMemo(
      () => [
        {
          id: 'positionId',
          header: translate('COMMON.LABELS.POSITION_ID'),
          minWidth: 100,
        },
        {
          id: 'symbol',
          header: translate('COMMON.LABELS.SYMBOL'),
          minWidth: 115,
        },
        {
          id: 'openTime',
          header: translate('COMMON.LABELS.OPEN_DATE'),
          minWidth: 190,
          cellStyle: { textAlign: 'left' },
          headerStyle: { textAlign: 'left' },
          render: row =>
            formatDate(new Date(row.openTime), 'Mm dd, yyyy hh:mm:ss', {
              locale: currentLng,
            }),
        },
        {
          id: 'closeTime',
          header: (
            <TableColumnDateFilter
              filterName='positionsHistory'
              dateFilterNames={{ from: 'completedFrom', to: 'completedTo' }}
              label={translate('COMMON.LABELS.CLOSE_DATE')}
            />
          ),
          minWidth: 190,
          cellStyle: { textAlign: 'left' },
          render: row =>
            formatDate(new Date(row.closeTime), 'Mm dd, yyyy hh:mm:ss', {
              locale: currentLng,
            }),
        },
        {
          id: 'side',
          header: translate('COMMON.LABELS.TYPE'),
          minWidth: getExtendedWidth('60', '2', ['nl']),
          render: row => <PurchaseType value={row.side as string} />,
        },
        {
          id: 'quantity',
          header: translate('COMMON.LABELS.VOLUME'),
          minWidth: 90,
        },
        {
          id: 'entryPrice',
          header: translate('COMMON.LABELS.OPEN_PRICE'),
          minWidth: 100,
        },
        {
          id: 'commission',
          header: translate('COMMON.LABELS.COMMISSION'),
          minWidth: 90,
        },
        { id: 'fee', header: translate('COMMON.LABELS.FEE'), minWidth: 80 },
        { id: 'swap', header: translate('COMMON.LABELS.SWAP'), minWidth: 80 },
        {
          id: 'stopLoss',
          header: translate('COMMON.LABELS.SL'),
          minWidth: 90,
          render: row =>
            formatTableRowValueByPipSize(row.stopLoss, row.pipSize),
        },
        {
          id: 'takeProfit',
          header: translate('COMMON.LABELS.TP'),
          minWidth: 90,
          render: row =>
            formatTableRowValueByPipSize(row.takeProfit, row.pipSize),
        },
        {
          id: 'exitPrice',
          header: translate('COMMON.LABELS.MARKET_PRICE'),
          minWidth: 100,
        },
        {
          id: 'pl',
          header: translate('COMMON.LABELS.PROFIT'),
          minWidth: 90,
          render: row => <ProfitValue value={row.pl.toFixed(2)} />,
        },
        {
          id: 'change',
          header: translate('COMMON.LABELS.CHANGE'),
          minWidth: 110,
          render: row => (
            <ChangePercentage
              entryValue={row.entryPrice}
              exitValue={row.exitPrice}
              side={row.side as TPlaceOrderSide}
            />
          ),
        },
      ],
      []
    );

    return (
      <Styled.Root>
        <ScrollableTable<IPositionHistoryBE>
          data={formattedPositionHistory}
          columns={columns}
          increaseViewportBy={100}
          emptyMessage={translate('COMMON.LABELS.NO_POSITIONS')}
          totalCount={totalData.totalCount}
          itemsPerPage={ITEMS_PER_PAGE}
          isLoading={
            !store.entities.positionsHistory.getPositionsHistoryAsync
              .hasEverBeenRun
          }
          keepHeaderOnEmptyMessage={isFilterApplied}
          withTransparentHeader={false}
          withBorder={true}
          onLoadMore={loadPositionsHistory}
        />
        {shouldShowFooter && renderFooter()}
      </Styled.Root>
    );
  }
);

import { useEffect } from 'react';

import { TTradingAccountEntity, useMst } from '@trader/store';
import { EConditionType, useBusinessLogic } from '@trader/hooks';
import { EAccountTypeNames, isChallengeAccountType } from '@trader/constants';

export const useAccountInformationData = () => {
  const store = useMst();
  const { isScoreLevelLow } = useBusinessLogic(EConditionType.ScoreLevel);

  const allAccounts =
    store.entities.tradingAccounts.getAll<TTradingAccountEntity>();
  const accounts = isScoreLevelLow
    ? allAccounts.filter(acc => acc.accountType === EAccountTypeNames.Demo)
    : allAccounts;

  useEffect(() => {
    store.entities.tradingAccounts.getTradingAccountsAsync.run();
    if (isChallengeAccountType) {
      store.entities.challenges.getChallengesAsync.run();
    }
  }, []);

  return { accounts };
};

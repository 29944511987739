import { FC } from 'react';
import { observer } from 'mobx-react-lite';

import { Icon, Wrapper } from '@trader/components';
import { TAlertEntity } from '@trader/store';
import { formatTableRowValueByPipSize } from '@trader/utils';

import * as Styled from '../../desktop.styled';

interface IAlertPrice {
  alert: TAlertEntity;
}

export const AlertPrice: FC<IAlertPrice> = observer(({ alert }) => {
  const instrument = alert.conditions[0].instrument;
  const rightExpression = alert?.conditions[0]?.rightExpression;
  const defaultAlertPrice = parseFloat(rightExpression?.parameters?.Number);

  return (
    <Wrapper alignItems='center' justifyContent='center'>
      <Styled.Title>
        {instrument?.currencySymbol}
        {defaultAlertPrice
          ? +formatTableRowValueByPipSize(
              defaultAlertPrice,
              instrument?.pipSize
            )
          : ''}
      </Styled.Title>
      <Icon
        style={{ margin: '0 0 1px 4px' }}
        iconType={
          alert?.conditions[0]?.compareType === 'BiggerThen'
            ? 'abovePrice'
            : 'belowPrice'
        }
      />
    </Wrapper>
  );
});

import React, { useMemo } from 'react';
import { debounce } from 'lodash';
import { Virtuoso } from 'react-virtuoso';

import { useI18next } from '@trader/services';
import { Progress, Typography } from '@trader/components';

interface IScrollableListProps<T extends object> {
  data: T[];
  renderItem: (item: T, index: number) => React.ReactNode;
  overscan?: number;
  increaseViewportBy?: number;
  totalCount?: number;
  itemsPerPage?: number;
  emptyMessage?: string;
  isLoading?: boolean;
  onLoadMore?: (pageNumber: number) => void;
}

const defaultOverscan = 10;
const debounceTime = 300;

export const ScrollableList = <T extends object>({
  data,
  renderItem,
  overscan = defaultOverscan,
  increaseViewportBy = defaultOverscan,
  totalCount,
  itemsPerPage,
  emptyMessage,
  isLoading,
  onLoadMore = () => null,
}: IScrollableListProps<T>) => {
  const { translate } = useI18next();

  const debouncedLoadMore = useMemo(
    () => debounce(onLoadMore, debounceTime),
    [onLoadMore]
  );

  if (isLoading) {
    return <Progress />;
  }

  if (!data || !data.length) {
    return (
      <Typography variant='medium' padding='12px' width='100%'>
        {emptyMessage || translate('COMMON.LABELS.NO_RECORDS_FOUND')}
      </Typography>
    );
  }

  const handleLoadMore = (lastItemIndex: number) => {
    if (itemsPerPage) {
      const pageNumber = Math.ceil(lastItemIndex / itemsPerPage) + 1;
      debouncedLoadMore(pageNumber);
    }
  };

  return (
    <Virtuoso
      data={data}
      totalCount={totalCount}
      itemContent={(index, item) => renderItem(item, index)}
      overscan={overscan}
      increaseViewportBy={increaseViewportBy}
      endReached={handleLoadMore}
      style={{ width: '100%' }}
    />
  );
};

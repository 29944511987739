import { FC } from 'react';
import { useMediaQuery, useTheme } from '@mui/material';

import { MobileBottomBar } from './mobile';
import { DesktopBottomBar } from './desktop';

export const BottomBar: FC = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return isMobile ? <MobileBottomBar /> : <DesktopBottomBar />;
};

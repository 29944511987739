import {
  calcEquity,
  calcFreeMargin,
  calcMarginLevel,
  formatAccountCurrencyValue,
  formatAccountPercentageValue,
} from '@trader/utils';
import { TTradingAccountEntity } from '@trader/store';
import { useTradingAccountPositions } from '@trader/hooks';
import { isChallengeAccountType } from '@trader/constants';

export const useAccountCalculatedValues = (account: TTradingAccountEntity) => {
  const { positionsPl } = useTradingAccountPositions(account.tradingId);
  const { portfolio, currencySymbol } = account;

  if (!portfolio) {
    return {
      accountId: isChallengeAccountType
        ? account.tradingId
        : account.platformLogin,
      pl: formatAccountCurrencyValue(positionsPl, currencySymbol),
      equity: null,
      freeMargin: null,
      usedMargin: null,
      marginLevel: null,
      availableForWithdrawal: null,
    };
  }

  const { balance, credit, usedMargin } = portfolio;
  const equity = calcEquity(positionsPl, balance, credit);
  const freeMargin = calcFreeMargin(equity, usedMargin);
  const marginLevel = calcMarginLevel(equity, usedMargin);
  const availableForWithdrawal = freeMargin < 0 ? null : freeMargin;

  return {
    accountId: isChallengeAccountType
      ? account.tradingId
      : account.platformLogin,
    pl: formatAccountCurrencyValue(positionsPl, currencySymbol),
    equity: formatAccountCurrencyValue(equity, currencySymbol),
    freeMargin: formatAccountCurrencyValue(freeMargin, currencySymbol),
    usedMargin: formatAccountCurrencyValue(usedMargin, currencySymbol),
    marginLevel: formatAccountPercentageValue(marginLevel),
    availableForWithdrawal: formatAccountCurrencyValue(
      availableForWithdrawal,
      currencySymbol
    ),
  };
};

import React from 'react';

import { EAccountTypeNames } from '@trader/constants';
import { TTradingAccountEntity, useMst } from '@trader/store';
import { useI18next } from '@trader/services';

import * as Styled from './desktop.styled';

export const AddFundsButton: React.FC<{
  account: TTradingAccountEntity;
}> = ({ account }) => {
  const { translate } = useI18next();
  const store = useMst();

  const handleButtonClick = () => {
    store.ui.modal.open('addFunds', {
      tradingAccountId: account.tradingId,
      tradingAccountSymbol: account.currencySymbol,
    });
  };

  return (
    <Styled.Button
      disabled={account.accountType === EAccountTypeNames.Demo}
      onClick={handleButtonClick}
    >
      {translate('ADD_FUNDS.TITLE')}
    </Styled.Button>
  );
};

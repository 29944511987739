import styled, { css } from 'styled-components';

import { Wrapper, Typography } from '@trader/components';

export const Root = styled(Wrapper)`
  ${({ theme }) => css`
    width: 100%;
    height: 100%;
    flex-direction: column;
    border-radius: 5px;
    background-color: ${theme.palette.common.white};
  `};
`;

export const Title = styled(Typography)`
  ${({ theme }) => css`
    font-size: ${theme.typography.heading.fontSize};
    font-weight: ${theme.typography.fontWeightMedium};
    padding: 10px 20px;
    border-bottom: 1px solid ${theme.palette.gray.light};
  `};
`;

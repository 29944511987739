import { useCallback } from 'react';
import { TLanguageKey, useI18next } from '@trader/services';

const whiteListToExtendWidth: Array<TLanguageKey> = ['es', 'it', 'de', 'pl'];
const defaultValueToMultiple = 2;

export const useExtendItemWidthDependsLng = () => {
  const { currentLng } = useI18next();

  const getExtendedWidth = useCallback(
    // make defaultWidth, valueToMultiple as string to avoid lint magic numbers error in files we use this func
    (
      defaultWidth: string,
      valueToMultiple?: string,
      customWhiteList?: Array<TLanguageKey>
    ) => {
      let extendedWidth = +defaultWidth;

      const multipleBy = valueToMultiple
        ? +valueToMultiple
        : defaultValueToMultiple;

      [...whiteListToExtendWidth, ...(customWhiteList || [])].forEach(lng => {
        if (lng === currentLng) {
          extendedWidth *= multipleBy;
        }
      });

      return Math.ceil(extendedWidth);
    },
    [currentLng]
  );

  return { getExtendedWidth };
};

import {
  createChart,
  CrosshairMode,
  IChartApi,
  LineStyle,
  LineWidth,
  SeriesDataItemTypeMap,
} from 'lightweight-charts';
import { DefaultTheme } from 'styled-components';

import { IMessage } from '@trader/types';
import { webSocketsService } from '@trader/services';
import { TInstrumentEntity } from '@trader/store';
import { getAccountTypeForConnection, productId } from '@trader/constants';

import { generateNewStick } from './utils/generateStick';
import { getMaxDigitWithDot } from '../numberFormats';
import { getTCInfoUByKey } from '../tradingCentral';
import { formatByPipSize } from '../trading';
import { formatDate } from '../dateUtils';
import { IBarFE } from './models';

export { initialState } from './models';
export { calculateSizes } from './utils/resize';

export type { IState, IBarFE } from './models';

export const defaultPrecision = 4;
const defaultPipSize = 0.0001;
const sizeToEndBeforeGetMoreCharts = 50;

interface IRenderChartsOutput {
  remove: () => void;
  chartObject: IChartApi;
}

interface IRenderChartsInput {
  htmlElement: HTMLElement;
  getBars: (fromTime?: number) => Promise<Array<IBarFE>>;
  period: string;
  theme: DefaultTheme;
  connection: ReturnType<typeof webSocketsService>;
  symbol: string;
  tradingCentral: TInstrumentEntity['tradingCentral'];
  activeTradingAccount: {
    platformLogin: string;
    accountType: string;
  };
  pipSize?: number;
  product: string;
}

export const renderCharts = ({
  htmlElement,
  getBars,
  tradingCentral,
  period,
  theme,
  connection,
  symbol,
  activeTradingAccount,
  pipSize = defaultPipSize,
  product,
}: IRenderChartsInput): IRenderChartsOutput => {
  let currentBars: Array<IBarFE> = [];
  let shouldGetMoreHistory = true;

  function start() {
    connection.subscribe(async hub => {
      await hub.send(
        'SubscribeOnQuote',
        symbol,
        1,
        productId[product],
        activeTradingAccount.platformLogin,
        getAccountTypeForConnection[activeTradingAccount.accountType]
      );
      hub.on('onQuote', handleMessage);
    });
  }

  const chart = createChart(htmlElement, {
    autoSize: true,
    layout: {
      background: {
        color: theme.palette.background.paper,
      },
      textColor: theme.palette.text.primary,
    },
    crosshair: {
      mode: CrosshairMode.Normal,
    },
    timeScale: {
      timeVisible: true,
      secondsVisible: true,
      barSpacing: 14,
      rightOffset: 5,
      borderColor: theme.palette.background.paper,
    },
    rightPriceScale: {
      mode: 0,
      borderColor: theme.palette.background.paper,
      autoScale: true,
      scaleMargins: {
        top: 0.2,
        bottom: 0.2,
      },
    },
    grid: {
      vertLines: {
        color: theme.palette.background.default,
      },
      horzLines: {
        color: theme.palette.background.default,
      },
    },
    localization: {
      priceFormatter: price => {
        const pipPrice = formatByPipSize(price, pipSize);
        const size = getMaxDigitWithDot(pipSize);
        return Number(pipPrice).toFixed(size);
      },
      timeFormatter: time => {
        return formatDate(time, 'dd/mm/yyyy hh:mm:ss');
      },
    },
  });

  const candleSeries = chart.addCandlestickSeries({
    upColor: '#00A57E',
    downColor: '#D80027',
    borderUpColor: '#00A57E',
    borderDownColor: '#D80027',
    wickUpColor: '#00A57E',
    wickDownColor: '#D80027',
    priceFormat: {
      minMove: +pipSize,
      precision: pipSize ? getMaxDigitWithDot(pipSize) : defaultPrecision,
      formatter: price => {
        const pipPrice = formatByPipSize(price, pipSize);
        const size = pipSize ? getMaxDigitWithDot(pipSize) : defaultPrecision;
        return Number(pipPrice).toFixed(size);
      },
    },
  });

  candleSeries.setData(
    currentBars as Array<SeriesDataItemTypeMap['Candlestick']>
  );

  async function fetchBars() {
    try {
      currentBars = await getBars();
      candleSeries.setData([...currentBars] as Array<
        SeriesDataItemTypeMap['Candlestick']
      >);
    } catch (e) {
      console.log('Error', { e });
    }
  }

  fetchBars();

  (function () {
    if (tradingCentral) {
      const indicators = tradingCentral?.feeds[0]?.chartLevel;

      if (indicators) {
        const pivotInfo = getTCInfoUByKey('pivot');
        const pivotPrice = indicators.pivot;
        candleSeries.createPriceLine({
          price: pivotPrice,
          color: pivotInfo.color,
          lineWidth: 1 as LineWidth,
          lineStyle: LineStyle.Solid,
          axisLabelVisible: true,
          title: pivotInfo.title,
        });

        for (const key in indicators) {
          if (key !== 'last' && indicators[key] !== pivotPrice) {
            const info = getTCInfoUByKey(key);
            const indicator = {
              price: indicators[key],
              color: info.color,
              lineWidth: 1 as LineWidth,
              lineStyle: LineStyle.Solid,
              axisLabelVisible: true,
              title: info.title,
            };

            candleSeries.createPriceLine(indicator);
          }
        }
      }
    }
  })();

  const timeScale = chart.timeScale();

  timeScale.subscribeVisibleLogicalRangeChange(() => {
    const logicalRange = timeScale.getVisibleLogicalRange();
    if (
      logicalRange &&
      logicalRange.from <= sizeToEndBeforeGetMoreCharts &&
      shouldGetMoreHistory
    ) {
      shouldGetMoreHistory = false;
      getMoreHistory();
    }
  });

  const getMoreHistory = async () => {
    const firstBar = currentBars[0];

    const response = await getBars(firstBar.time);
    candleSeries.setData([...response, ...currentBars] as Array<
      SeriesDataItemTypeMap['Candlestick']
    >);
    currentBars = [...response, ...currentBars];
    shouldGetMoreHistory = !!response.length;
  };

  const handleMessage = (message: IMessage) => {
    if (message.s !== symbol) {
      return;
    }

    const price = formatByPipSize(message.a, pipSize);
    const generatedNewBars = generateNewStick(
      Number(price),
      candleSeries,
      currentBars,
      period
    );

    if (generatedNewBars) {
      currentBars = generatedNewBars;
    }
  };

  start();

  return {
    remove: () => {
      chart.removeSeries(candleSeries);
      chart.remove();
    },
    chartObject: chart,
  };
};

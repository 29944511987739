export const amountOfRetries = 3;
export const reconnectTimeout = 1000;

export const autoHideSnackbar = 5000;

export const keepAliveInterval = 3000;
export const serverTimeout = 10000;

export const timeToCloseSnackbar = 30000;

// eslint-disable-next-line no-magic-numbers
export const retryDelays = [0, 500, 1000, 1500];

export const webSocketsUrls = {
  quotes: '/v4/quotes',
  inboxes: '/inboxes',
  auth: '/auth',
  strategy: '/strategy',
  account: '/v2/account',
} as const;

import * as yup from 'yup';

import {
  checkOnTickSize,
  adjustByTickSize,
  formatByPipSize,
  getPriceDifference,
  handleIndicesPipSize,
} from '@trader/utils';
import { TInstrumentEntity, useMst } from '@trader/store';
import { TCommonOrderType, TPlaceOrderSide } from '@trader/types';
import { useI18next } from '@trader/services';
import { defaultPipSize } from '@trader/constants';
import Decimal from 'decimal.js';

export const usePriceSchema = (type: TCommonOrderType) => {
  const { translate } = useI18next();
  const store = useMst();

  const trading = store.trading.getTrading(type);
  const instrument = trading.instrument as TInstrumentEntity;

  const actualPipSize = instrument.pipSize;
  const category = instrument?.category;
  const minOrderSizeIncrement = instrument.minOrderSizeIncrement;

  const priceDiff = getPriceDifference(
    instrument.stopsLevel,
    instrument.pipSize
  );

  const pipSize = handleIndicesPipSize(
    actualPipSize ?? defaultPipSize,
    category || undefined,
    minOrderSizeIncrement ?? 1
  );

  const getStops = (orderSide: TPlaceOrderSide, ask: number, bid: number) => {
    const value = new Decimal(orderSide === 'Buy' ? bid : ask);
    const stopsUp = adjustByTickSize(
      formatByPipSize(value.plus(priceDiff).toNumber(), instrument.pipSize),
      pipSize,
      instrument.tickSize,
      orderSide,
      'price'
    );
    const stopsDown = adjustByTickSize(
      formatByPipSize(value.minus(priceDiff).toNumber(), instrument.pipSize),
      pipSize,
      instrument.tickSize,
      orderSide,
      'price'
    );

    return { stopsUp, stopsDown };
  };

  const priceSchema = yup
    .number()
    .min(
      instrument.pipSize,
      translate('COMMON.ERRORS.MUST_BE_HIGHER_THAN', {
        amount: instrument.pipSize,
      })
    )
    .when(
      ['side', 'ask', 'bid', 'orderType'],
      ([side, ask, bid, orderType], schema) =>
        schema.test(
          'trading-diff',
          translate(
            'COMMON.ERRORS.SHOULD_BE_OUTSIDE_RANGE',
            getStops(side, ask, bid)
          ),
          value => {
            if (orderType !== 'Market') {
              if (value) {
                const { stopsDown, stopsUp } = getStops(side, ask, bid);
                return !(+value > +stopsDown && +value < +stopsUp);
              }
            }
            return true;
          }
        )
    )
    .when(['orderType', 'side', 'ask', 'bid'], ([orderType], schema) =>
      schema.test(
        'tick_diff',
        translate('COMMON.ERRORS.TICK_SIZE', {
          tickSize: instrument.tickSize,
        }),
        value => {
          if (orderType !== 'Market') {
            if (value) {
              return !checkOnTickSize(value, instrument.tickSize);
            }
          }
          return true;
        }
      )
    )
    .typeError(
      translate('COMMON.ERRORS.MUST_BE_HIGHER_THAN', {
        amount: instrument.pipSize,
      })
    );

  return { priceSchema };
};

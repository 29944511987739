import { returnTypedThis } from '@trader/utils';
import { SmartLookService } from '@trader/services';
import {
  api,
  IMakeDepositBody,
  ITransactionsBE,
  ITransferParamsBE,
  ITransfersHistoryBE,
  IGetPspsParams,
  IPspsResponseData,
  IPurchasesHistoryBE,
  IGetAvailablePaymentMethodsParams,
  IAvailablePaymentMethodBE,
  IGetDepositLinkBody,
  IPurchaseParamsBE,
} from '@trader/api';

import { getRootInstance } from '../configureStore/configureStore';
import { TUserStore } from './index';
import { createThunk } from '../utils/asyncModel';

export const getProfileDemoAsync = createThunk<void, void>(
  () =>
    async function getProfile(this: unknown, _options, _flow): Promise<void> {
      const root = getRootInstance();
      const that = returnTypedThis<TUserStore>(this);
      const response = await api.User.getUserProfile(_options);

      SmartLookService.identify({
        userId: root.auth.tokens.cognitoId,
        email: response?.email || '',
        name: `${response?.firstName || ''} ${response?.lastName || ''}`,
      });

      that.runInAction(() => {
        that.profile = {
          ...response,
          isDepositAllowed: Boolean(response?.isDepositAllowed),
          isAcceptedAccountApproved: Boolean(
            response?.isAcceptedAccountApproved
          ),
        };
      });
    }
);

export const makeDepositAsync = createThunk<IMakeDepositBody, string>(
  body =>
    async function makeDeposit(
      this: unknown,
      _options,
      _flow
    ): Promise<string> {
      const root = getRootInstance();
      const response = await api.Payment.makeDeposit(body, _options);

      SmartLookService.track('MakeDeposit', {
        userId: root.auth.tokens?.cognitoId,
        email: root.user.profile?.email,
        name: `${root.user.profile?.firstName} ${root.user.profile?.lastName}`,
        deposit: body.amount,
        tradingAccId: body.tradingAccountId || 0,
        pspId: body.pspId || 0,
      });

      return response?.returnUrl;
    }
);

export const getTransactionsAsync = createThunk<void, ITransactionsBE>(
  () =>
    async function getTransactions(
      this: unknown,
      _options,
      _flow
    ): Promise<ITransactionsBE> {
      return await api.Payment.getTransactions(_options);
    }
);

export const getPspsAsync = createThunk<IGetPspsParams, IPspsResponseData>(
  params =>
    async function getPsps(
      this: unknown,
      _options,
      _flow
    ): Promise<IPspsResponseData> {
      return await api.Payment.getPsps(params, _options);
    }
);

export const getAvailablePaymentMethodsAsync = createThunk<
  IGetAvailablePaymentMethodsParams,
  IAvailablePaymentMethodBE[]
>(
  params =>
    async function getAvailablePaymentMethods(this: unknown, _options, _flow) {
      return await api.Payment.getAvailablePaymentMethods(params, _options);
    }
);

export const getDepositLinkAsync = createThunk<IGetDepositLinkBody, string>(
  params =>
    async function getDepositLink(this: unknown, _options, _flow) {
      return await api.Payment.getDepositLink(params, _options);
    }
);

export const getTransfersHistoryAsync = createThunk<
  ITransferParamsBE,
  ITransfersHistoryBE
>(
  params =>
    async function getTransfersHistory(
      this: unknown,
      _options,
      _flow
    ): Promise<ITransfersHistoryBE> {
      return await api.Payment.getTransfersHistory(params, _options);
    }
);

export const getPurchaseHistoryAsync = createThunk<
  IPurchaseParamsBE,
  IPurchasesHistoryBE
>(
  params =>
    async function getTransfersHistory(
      this: unknown,
      _options,
      _flow
    ): Promise<IPurchasesHistoryBE> {
      return await api.Payment.getPurchaseHistory(params, _options);
    }
);

export const getSettingsAsync = createThunk<void, void>(
  () =>
    async function getSettings(this: unknown, _options, _flow): Promise<void> {
      const that = returnTypedThis<TUserStore>(this);
      const response = await api.User.getUserSettings(_options);

      that.runInAction(() => {
        that.settings = {
          ...response,
          isDisplayedByUser:
            response?.isCrmTradingCentral && response?.isTradingCentral,
          isEdgeXDisplayedByUser: response?.isCrmEdgeX && response?.isEdgeX,
          isXStrategyDisplayedByUser:
            response?.isXStrategy && response?.isCrmXStrategy,
        };
      });
    }
);

interface IUserUpdateSettings {
  isTradingCentral: boolean;
  isEdgeX: boolean;
  isXStrategy: boolean;
}

export const updateSettingsAsync = createThunk<IUserUpdateSettings, void>(
  ({ isTradingCentral, isEdgeX, isXStrategy }) =>
    async function updateSettings(
      this: unknown,
      _options,
      _flow
    ): Promise<void> {
      const that = returnTypedThis<TUserStore>(this);
      const root = getRootInstance();

      await api.User.updateUserSettings(
        isTradingCentral,
        isEdgeX,
        isXStrategy,
        _options
      );

      if (!isTradingCentral) {
        root.ui.sidebar.closeRightBarInfoSection();
      }
      that.runInAction(() => {
        that.settings.isTradingCentral = isTradingCentral;
        that.settings.isDisplayedByUser = isTradingCentral;
        that.settings.isEdgeX = isEdgeX;
        that.settings.isEdgeXDisplayedByUser = isEdgeX;
        that.settings.isXStrategy = isXStrategy;
        that.settings.isXStrategyDisplayedByUser = isXStrategy;
      });
    }
);

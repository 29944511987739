import React, { useMemo } from 'react';
import { observer } from 'mobx-react-lite';

import {
  calcEquity,
  calcFreeMargin,
  calcMarginLevel,
  formatAccountCurrencyValue,
  formatAccountPercentageValue,
} from '@trader/utils';
import {
  useExtendItemWidthDependsLng,
  usePositionMetricsPl,
  usePositionMetricsAssets,
} from '@trader/hooks';
import { shouldDisplayCreditInPortfolio } from '@trader/constants';
import { useMst } from '@trader/store';
import { useI18next } from '@trader/services';

import * as Styled from './styled';

interface IBalanceDetails {
  isShowDetails: boolean;
}

export const BalanceDetails: React.FC<IBalanceDetails> = observer(
  ({ isShowDetails }) => {
    const { translate, currentLng } = useI18next();
    const { positionsPl } = usePositionMetricsPl();
    const { positionsAssets } = usePositionMetricsAssets();
    const { getExtendedWidth } = useExtendItemWidthDependsLng();

    const store = useMst();
    const portfolio = store.user.portfolio();
    const tradingAccount = store.user.activeTradingAcc();

    const currencySymbol = tradingAccount.currencySymbol;
    const equity = calcEquity(positionsPl, portfolio.balance, portfolio.credit);

    const containersWidth = useMemo(
      () => ({
        // eslint-disable-next-line no-magic-numbers
        assets: () => 70,
        equity: () => getExtendedWidth('60', '1.5', ['nl', 'sk']),
        freeMargin: () =>
          getExtendedWidth('60', '1.12', ['es', 'it', 'de', 'nl']),
        usedMargin: () => {
          // eslint-disable-next-line no-magic-numbers
          if (currentLng === 'pl') return 100;
          // eslint-disable-next-line no-magic-numbers
          if (currentLng === 'de' || currentLng === 'nl') return 90;
          return getExtendedWidth('60', '1.2', ['es', 'it', 'sk']);
        },
        marginLevel: () =>
          getExtendedWidth('60', '1.2', ['es', 'it', 'de', 'nl', 'sk']),
      }),
      []
    );

    return (
      <Styled.Root
        $isShowDetails={isShowDetails}
        $shouldIncreaseWidth={currentLng !== 'en'}
      >
        <Styled.BalanceContainer>
          <Styled.Title>{translate('COMMON.LABELS.BALANCE')}</Styled.Title>
          <Styled.Value>
            {formatAccountCurrencyValue(portfolio.balance, currencySymbol)}
          </Styled.Value>
        </Styled.BalanceContainer>

        {shouldDisplayCreditInPortfolio && (
          <Styled.BalanceContainer>
            <Styled.Title>{translate('COMMON.LABELS.CREDIT')}</Styled.Title>
            <Styled.Value>
              {formatAccountCurrencyValue(portfolio.credit, currencySymbol)}
            </Styled.Value>
          </Styled.BalanceContainer>
        )}

        <Styled.BalanceContainer $width={containersWidth.equity()}>
          <Styled.Title>{translate('COMMON.LABELS.EQUITY')}</Styled.Title>
          <Styled.Value>
            {formatAccountCurrencyValue(equity, currencySymbol)}
          </Styled.Value>
        </Styled.BalanceContainer>

        <Styled.BalanceContainer $width={containersWidth.assets()}>
          <Styled.Title>{translate('COMMON.LABELS.ASSETS')}</Styled.Title>
          <Styled.Value>
            {formatAccountCurrencyValue(positionsAssets, currencySymbol)}
          </Styled.Value>
        </Styled.BalanceContainer>

        <Styled.BalanceContainer>
          <Styled.Title>{translate('COMMON.LABELS.P_L')}</Styled.Title>
          <Styled.Value>
            {formatAccountCurrencyValue(positionsPl, currencySymbol)}
          </Styled.Value>
        </Styled.BalanceContainer>

        <Styled.BalanceContainer $width={containersWidth.freeMargin()}>
          <Styled.Title>{translate('COMMON.LABELS.FREE_MARGIN')}</Styled.Title>
          <Styled.Value>
            {formatAccountCurrencyValue(
              calcFreeMargin(equity, portfolio.usedMargin),
              currencySymbol
            )}
          </Styled.Value>
        </Styled.BalanceContainer>

        <Styled.BalanceContainer $width={containersWidth.usedMargin()}>
          <Styled.Title>{translate('COMMON.LABELS.USED_MARGIN')}</Styled.Title>
          <Styled.Value>
            {formatAccountCurrencyValue(portfolio.usedMargin, currencySymbol)}
          </Styled.Value>
        </Styled.BalanceContainer>

        <Styled.BalanceContainer $width={containersWidth.marginLevel()}>
          <Styled.Title>{translate('COMMON.LABELS.MARGIN_LEVEL')}</Styled.Title>
          <Styled.Value>
            {formatAccountPercentageValue(
              calcMarginLevel(equity, portfolio.usedMargin)
            )}
          </Styled.Value>
        </Styled.BalanceContainer>
      </Styled.Root>
    );
  }
);

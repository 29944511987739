import { useMemo } from 'react';
import { observer } from 'mobx-react-lite';

import {
  IScrollableTableColumn,
  ScrollableTable,
  ObservableTableColumn,
} from '@trader/components';
import { useExtendItemWidthDependsLng } from '@trader/hooks';
import { TAlertEntity } from '@trader/store';
import { useI18next } from '@trader/services';
import { formatDate } from '@trader/utils';

import { SymbolWithIcon } from '../scrollingList';
import { useAlertsListData } from './hooks/useAlertsListData';
import { AlertActions } from './components/alertActions';
import { AlertSide } from './components/alertSide';
import { AlertPrice } from './components/alertPrice';
import { AlertCurrentPrice } from './components/alertCurrentPrice';
import * as Styled from './desktop.styled';

export const DesktopAlertsList = observer(() => {
  const { currentLng, translate } = useI18next();
  const { getExtendedWidth } = useExtendItemWidthDependsLng();
  const { alerts } = useAlertsListData();

  const columns: IScrollableTableColumn<TAlertEntity>[] = useMemo(
    () => [
      {
        id: 'symbol',
        header: translate('COMMON.LABELS.SYMBOL'),
        minWidth: 115,
        cellStyle: { paddingLeft: '15px' },
        headerStyle: { textAlign: 'left', paddingLeft: '20px' },
        render: row => {
          const instrument = row.conditions[0].instrument;
          return (
            <SymbolWithIcon
              symbol={instrument?.symbol}
              iconUrl={instrument?.iconUrl}
            />
          );
        },
      },
      {
        id: 'side',
        header: translate('COMMON.LABELS.TYPE'),
        minWidth: getExtendedWidth('60', '2', ['nl']),
        render: row => <AlertSide alert={row} />,
      },
      {
        id: 'name',
        header: translate('COMMON.LABELS.CREATION_DATE'),
        minWidth: 190,
        render: row =>
          formatDate(new Date(row.name), 'Mm dd, yyyy hh:mm:ss', {
            locale: currentLng,
          }),
      },
      {
        id: 'endTime',
        header: translate('COMMON.LABELS.EXPIRATION_DATE'),
        minWidth: 190,
        render: row =>
          row.endTime
            ? formatDate(
                new Date(row.endTime as string),
                'Mm dd, yyyy hh:mm:ss',
                { locale: currentLng }
              )
            : '',
      },
      {
        id: 'alertPrice',
        header: translate('COMMON.LABELS.ALERT_WHEN'),
        minWidth: 110,
        render: row => (
          <ObservableTableColumn
            row={row}
            render={r => <AlertPrice alert={r} />}
          />
        ),
      },
      {
        id: 'currentPrice',
        header: translate('COMMON.LABELS.CURRENT_PRICE'),
        minWidth: 110,
        render: row => (
          <ObservableTableColumn
            row={row}
            render={r => <AlertCurrentPrice alert={r} />}
          />
        ),
      },
      {
        id: 'actions',
        header: '',
        minWidth: getExtendedWidth('80', '1.35', ['sk']),
        cellStyle: { paddingLeft: '15px' },
        headerStyle: { textAlign: 'right', paddingRight: '20px' },
        render: row => <AlertActions alert={row} />,
      },
    ],
    []
  );

  return (
    <Styled.Root>
      <ScrollableTable<TAlertEntity> data={alerts} columns={columns} />
    </Styled.Root>
  );
});

import { TFormatterType, TSupportedLocales } from './types';

interface IFormatDateOptions {
  shouldUseUTC?: boolean;
  locale?: TSupportedLocales;
}

const formatPatterns: Record<TFormatterType, string> = {
  'dd/mm/yyyy hh:mm:ss': '{dd}/{mm}/{yyyy} {hh}:{ii}:{ss}',
  'dd/mm/yyyy hh:mm': '{dd}/{mm}/{yyyy} {hh}:{ii}',
  'dd/mm/yyyy; hh:mm:ss': '{dd}/{mm}/{yyyy}; {hh}:{ii}:{ss}',
  'dd.mm.yyyy': '{dd}.{mm}.{yyyy}',
  'dd.mm.yyyy hh:mm:ss': '{dd}.{mm}.{yyyy} {hh}:{ii}:{ss}',
  'dd.mm.yyyy hh:mm': '{dd}.{mm}.{yyyy} {hh}:{ii}',
  'Mm dd, yyyy hh:mm:ss': '{Mm} {dd}, {yyyy} {hh}:{ii}:{ss}',
  'Mm dd, yyyy': '{Mm} {dd}, {yyyy}',
  'YYYY-MM-DD': '{yyyy}-{mm}-{dd}',
  'hh:mm:ss': '{hh}:{ii}:{ss}',
};

export const formatDate = (
  date: string | number | Date,
  format: TFormatterType,
  options: IFormatDateOptions = {}
): string => {
  const { shouldUseUTC = true, locale = 'en' } = options;
  const d = new Date(date);
  if (isNaN(d.getTime())) {
    throw new Error(`Invalid date: ${date}`);
  }

  const pad = (num: number) => String(num).padStart(2, '0');

  const formatOptions: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    hour12: false,
    timeZone: shouldUseUTC ? 'UTC' : undefined,
  };

  const formatter = new Intl.DateTimeFormat(locale, formatOptions);
  const parts = formatter.formatToParts(d);
  const partMap = Object.fromEntries(parts.map(p => [p.type, p.value]));

  const replacements = {
    dd: pad(parseInt(partMap.day)),
    mm: pad(parseInt(partMap.month)),
    yyyy: partMap.year,
    hh: pad(parseInt(partMap.hour)),
    ii: pad(parseInt(partMap.minute)),
    ss: pad(parseInt(partMap.second)),
    Mm: new Intl.DateTimeFormat(locale, {
      month: 'long',
      timeZone: shouldUseUTC ? 'UTC' : undefined,
    }).format(d),
  };

  return formatPatterns[format].replace(
    /{(.*?)}/g,
    (_, key) => replacements[key as keyof typeof replacements]
  );
};

import React from 'react';
import { observer } from 'mobx-react-lite';
import { Menu } from '@mui/material';
import { useTheme } from 'styled-components';

import { IconButton, Icon } from '@trader/components';
import { useMenuListItems } from '@trader/hooks';

import { TradingAccounts } from '../tradingAccounts';
import * as Styled from './desktop.styled';

export const DesktopMenu: React.FC = observer(() => {
  const theme = useTheme();
  const { getMenu } = useMenuListItems();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const isOpen = Boolean(anchorEl);

  const closeMenu = () => {
    setAnchorEl(null);
  };

  const toggleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    anchorEl ? setAnchorEl(null) : setAnchorEl(event.currentTarget);
  };

  return (
    <Styled.Root>
      <IconButton
        iconType='menu'
        id='menu-button'
        aria-controls={isOpen ? 'basic-menu' : undefined}
        aria-haspopup='true'
        aria-expanded={isOpen ? 'true' : undefined}
        onClick={toggleOpen}
      />
      <Menu
        id='basic-menu'
        open={isOpen}
        anchorEl={anchorEl}
        onClose={toggleOpen}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        marginThreshold={10}
        slotProps={{
          paper: {
            style: {
              width: '250px',
              maxWidth: '250px',
              borderRadius: 8,
              overflow: 'auto',
              maxHeight: 700,
              boxShadow: theme.palette.boxShadow.main,
              backgroundImage: 'none',
              background: theme.palette.menu.main,
            },
          },
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: -5,
          horizontal: 'center',
        }}
      >
        <TradingAccounts onClose={closeMenu} />

        <Styled.PaperArrow iconType='paperArrow' />
        {getMenu(closeMenu).map(item => (
          <Styled.MenuItem
            disabled={item.disabled}
            key={item.id}
            onClick={() => item?.action()}
          >
            {item.icon && <Icon iconType={item.icon} />}
            <Styled.Children>{item.children}</Styled.Children>
          </Styled.MenuItem>
        ))}
      </Menu>
    </Styled.Root>
  );
});

import { useEffect } from 'react';

import {
  EConnectionHub,
  EConnectionSubscription,
  HubConnection,
} from '@trader/services';
import { IMessage } from '@trader/types';
import { TInstrumentEntity, useMst } from '@trader/store';
import { formatByPipSize, getSpreadDifference } from '@trader/utils';
import { getAccountTypeForConnection, productId } from '@trader/constants';

import { useStartConnection } from './core';

export const useInstruments = (instrumentsIds: Array<string>) => {
  const store = useMst();

  const { connection, isAppDataLoaded } = useStartConnection(
    EConnectionHub.Quotes,
    EConnectionSubscription.Watchlist,
    'quotes'
  );

  const idToken = store.auth.tokens.idToken;
  const activeTradingAccount = store.user.activeTradingAcc();
  const platformLogin = activeTradingAccount?.platformLogin;
  const accountType = activeTradingAccount?.accountType;
  const product = store.user.getAccountProduct();

  const handleMessage = (message: IMessage) => {
    const currentInstrument = store.entities.instruments.get<TInstrumentEntity>(
      message.s
    );
    const { ask, bid } = getSpreadDifference(
      currentInstrument.spreadDiff,
      message.a,
      message.b,
      currentInstrument.spreadDiffBalance,
      currentInstrument.pipSize
    );
    if (ask !== currentInstrument.ask) {
      store.entities.instruments.update(message.s, {
        updateAskType:
          formatByPipSize(ask, currentInstrument.pipSize) >
          formatByPipSize(currentInstrument.ask, currentInstrument.pipSize)
            ? 'up'
            : 'down',
        ask: +formatByPipSize(ask, currentInstrument.pipSize),
      });
    }

    if (bid !== currentInstrument.bid) {
      store.entities.instruments.update(message.s, {
        updateBidType:
          formatByPipSize(bid, currentInstrument.pipSize) >
          formatByPipSize(currentInstrument.bid, currentInstrument.pipSize)
            ? 'up'
            : 'down',
        bid: +formatByPipSize(bid, currentInstrument.pipSize),
      });
    }
  };

  const handleSubscribe = async (hub: HubConnection) => {
    await hub.send(
      'SubscribeOnQuotes',
      instrumentsIds,
      1,
      productId[product],
      platformLogin,
      getAccountTypeForConnection[accountType]
    );
    hub.on('onQuote', handleMessage);
  };

  useEffect(() => {
    if (
      instrumentsIds.length &&
      platformLogin &&
      accountType &&
      isAppDataLoaded
    ) {
      connection.subscribe(handleSubscribe);
    }

    return () => {
      connection.unsubscribe(async hub => {
        await hub.send('UnsubscribeFromAllQuotes');
      });
    };
  }, [JSON.stringify(instrumentsIds), platformLogin, idToken, isAppDataLoaded]);
};

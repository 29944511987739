import React, { useMemo, useState } from 'react';
import { observer } from 'mobx-react-lite';

import {
  ITransferHistoryBE,
  ITransfersHistoryBE,
  TPaymentStatus,
  TTransferType,
} from '@trader/api';
import {
  IHeadCell,
  IRenderRowItemsCallBacks,
  Progress,
  ScrollingList,
} from '@trader/components';
import { useMst } from '@trader/store';
import { formatDate } from '@trader/utils';
import { useI18next } from '@trader/services';
import { TDateFilterInterval } from '@trader/types';

import { useTranslationMaps } from '../../hooks/useTranslationMaps';
import { TransferFilters } from './filters';
import * as Styled from './styled';

interface IState {
  isFetchingMore: boolean;
  isLoading: boolean;
  transfers: ITransfersHistoryBE['transfers'];
  totalCount: number;
}

export const TransfersHistory: React.FC = observer(() => {
  const store = useMst();
  const { currentLng, translate } = useI18next();
  const { statusTranslationMap, transferTypesTranslationMap } =
    useTranslationMaps();
  const [state, setState] = useState<IState>({
    isFetchingMore: false,
    isLoading: false,
    transfers: [],
    totalCount: 0,
  });

  const fetchTransfersHistory = async (
    statuses?: TPaymentStatus[],
    transferTypes?: TTransferType[],
    interval?: TDateFilterInterval | null
  ) => {
    const response = await store.user.getTransfersHistoryAsync.run({
      statuses,
      transferTypes,
      completedFrom: interval?.from?.toISOString() || undefined,
      completedTo: interval?.to?.toISOString() || undefined,
    });
    if (response) {
      setState(prev => ({
        ...prev,
        totalCount: response.totalCount,
        isLoading: false,
        transfers: response.transfers,
      }));
    }
  };

  const fetchMoreHistory = async (pageNumber: number) => {
    setState(prev => ({ ...prev, isFetchingMore: true }));
    const response = await store.user.getTransfersHistoryAsync.run({
      pageNumber,
    });
    if (response) {
      setState(prev => ({
        ...prev,
        transfers: [...state.transfers, ...response.transfers],
      }));
    }
  };

  const thousand = 1000;

  const renderRowItemsCallBacks: IRenderRowItemsCallBacks<ITransferHistoryBE> =
    useMemo(
      () => ({
        transferId: item => item.value,
        currencySymbol: item => item.value,
        value: item => item.value,
        status: item => statusTranslationMap[item.value as TPaymentStatus],
        transferType: item =>
          transferTypesTranslationMap[item.value as TTransferType],
        'date.seconds': item =>
          formatDate(
            new Date((item?.value as number) * thousand),
            'Mm dd, yyyy',
            { locale: currentLng }
          ),
      }),
      []
    );

  const cells: Array<IHeadCell> = [
    {
      id: 'transferId',
      label: translate('COMMON.LABELS.TRANSFER'),
      minWidth: 60,
      align: 'start',
    },
    {
      id: 'currencySymbol',
      label: translate('COMMON.LABELS.SYMBOL'),
      minWidth: 40,
      align: 'center',
    },
    {
      id: 'value',
      label: translate('COMMON.LABELS.AMOUNT'),
      minWidth: 60,
      align: 'center',
    },
    {
      id: 'status',
      label: 'Status',
      minWidth: 120,
      align: 'center',
    },
    {
      id: 'transferType',
      label: translate('COMMON.LABELS.TYPE'),
      minWidth: 70,
      align: 'start',
    },
    {
      id: 'date.seconds',
      label: translate('COMMON.LABELS.DATE'),
      minWidth: 120,
      align: 'start',
    },
  ];

  const renderTransfersList = () => {
    if (
      !state.isFetchingMore &&
      store.user.getTransfersHistoryAsync.inProgress
    ) {
      return <Progress />;
    }

    return (
      <ScrollingList<ITransferHistoryBE>
        rows={state.transfers}
        headCells={cells}
        renderRowItemsCallBacks={renderRowItemsCallBacks}
        totalCount={state.totalCount}
        fetchMore={fetchMoreHistory}
        emptyMessage={translate('COMMON.LABELS.NO_TRANSFERS')}
      />
    );
  };

  return (
    <Styled.Root>
      <TransferFilters onFiltersChange={fetchTransfersHistory} />
      {renderTransfersList()}
    </Styled.Root>
  );
});

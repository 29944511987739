import { FC } from 'react';
import { Stack, useTheme } from '@mui/material';
import { observer } from 'mobx-react-lite';

import {
  Icon,
  IScrollableListItemDetail,
  ScrollableList,
  ScrollableListItem,
  Typography,
  Wrapper,
} from '@trader/components';
import { TTradingAccountEntity, useMst } from '@trader/store';
import { useI18next } from '@trader/services';
import { EAccountTypeNames, isChallengeAccountType } from '@trader/constants';

import { useAccountInformationData } from './useAccountInformationData';
import { useAccountCalculatedValues } from './useAccountCalculatedValues';
import { ChangeAccountButton } from './changeAccountButton';
import { AddFundsButton } from './addFundsButton';
import * as DesktopStyled from './desktop.styled';
import * as Styled from './mobile.styled';

export const MobileAccountInformation = () => {
  const { accounts } = useAccountInformationData();

  return (
    <ScrollableList<TTradingAccountEntity>
      data={accounts}
      renderItem={a => <Account account={a} />}
    />
  );
};

const Account: FC<{ account: TTradingAccountEntity }> = observer(
  ({ account }) => {
    const { translate } = useI18next();
    const theme = useTheme();
    const accountCalculatedValues = useAccountCalculatedValues(account);

    const store = useMst();
    const challenge = store.user.getAccountChallenge(account.tradingId);

    const isLiveAccount = account.accountType === EAccountTypeNames.Live;

    const detailsItems: IScrollableListItemDetail[] = [
      {
        id: 'pl',
        header: translate('COMMON.LABELS.P_L'),
        value: accountCalculatedValues.pl,
      },
      {
        id: 'equity',
        header: translate('COMMON.LABELS.EQUITY'),
        value: accountCalculatedValues.equity,
      },
      {
        id: 'freeMargin',
        header: translate('COMMON.LABELS.FREE_MARGIN'),
        value: accountCalculatedValues.freeMargin,
      },
      {
        id: 'usedMargin',
        header: translate('COMMON.LABELS.USED_MARGIN'),
        value: accountCalculatedValues.usedMargin,
      },
      {
        id: 'marginLevel',
        header: translate('COMMON.LABELS.MARGIN_LEVEL'),
        value: accountCalculatedValues.marginLevel,
      },
    ];
    if (isLiveAccount) {
      detailsItems.push({
        id: 'availableForWithdrawal',
        header: translate('COMMON.LABELS.AVAILABLE_FOR_WITHDRAWAL'),
        value: accountCalculatedValues.availableForWithdrawal,
      });
    }

    return (
      <ScrollableListItem
        leadingContent={
          <Wrapper alignItems='center'>
            <Icon
              iconType='account'
              color={account.isInUse ? theme.palette.green['400'] : undefined}
            />
            <Typography variant='medium' fontWeight='medium' marginLeft='4px'>
              {accountCalculatedValues.accountId}
            </Typography>
          </Wrapper>
        }
        trailingContent={
          !isChallengeAccountType ? (
            <Stack direction='row' spacing='6px'>
              <DesktopStyled.Badge>{account.product}</DesktopStyled.Badge>
              <DesktopStyled.Badge>
                {translate(
                  `COMMON.LABELS.${account.accountType.toUpperCase()}`
                )}
              </DesktopStyled.Badge>
              <DesktopStyled.Badge>{account.currency}</DesktopStyled.Badge>
            </Stack>
          ) : null
        }
        detailsItems={detailsItems}
        underDetailsContent={
          <Styled.Buttons direction='row' spacing='8px'>
            {isLiveAccount && <AddFundsButton account={account} />}
            <ChangeAccountButton account={account} challenge={challenge} />
          </Styled.Buttons>
        }
      />
    );
  }
);

import React from 'react';
import { observer } from 'mobx-react-lite';

import { BalanceDetails, Footer } from '@trader/components';

import * as Styled from './styled';

export const MobileBottomBar: React.FC = observer(() => {
  return (
    <Footer>
      <Styled.BalanceDetails>
        <BalanceDetails isShowDetails />
      </Styled.BalanceDetails>
    </Footer>
  );
});

import { useEffect } from 'react';

import { useMst } from '@trader/store';
import { EConnectionHub, EConnectionSubscription } from '@trader/services';

import { useStartConnection } from './core';

enum ESignalType {
  EdgeX,
  EdgeZone,
}

interface IMessage {
  signalType: ESignalType;
  isEnabled: boolean;
}

export const useSignalPurchase = () => {
  const store = useMst();

  const { connection } = useStartConnection(
    EConnectionHub.Inboxes,
    EConnectionSubscription.SignalPurchase,
    'inboxes'
  );

  const idToken = store.auth.tokens.idToken;

  const handleMessage = (message: IMessage) => {
    if (message.signalType === ESignalType.EdgeX) {
      store.user.runInAction(() => {
        store.user.settings.isCrmEdgeX = message.isEnabled;
        store.user.settings.isEdgeXDisplayedByUser = message.isEnabled;
        store.user.settings.isEdgeX = message.isEnabled;
      });
    }
    if (message.signalType === ESignalType.EdgeZone) {
      store.user.runInAction(() => {
        store.user.settings.isCrmXStrategy = message.isEnabled;
        store.user.settings.isXStrategyDisplayedByUser = message.isEnabled;
        store.user.settings.isXStrategy = message.isEnabled;
      });
    }
  };

  useEffect(() => {
    if (store.auth.isAuth) {
      connection.subscribe(hub => {
        hub.on('OnSignalPurchase', handleMessage);
      });
    }
  }, [store.auth.isAuth, idToken]);
};

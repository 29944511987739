import { Fragment, ReactNode } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import { useTheme } from 'styled-components';
import { DateTimePicker as MuiDateTimePicker } from '@mui/x-date-pickers/DateTimePicker';

import { IChangeFieldEvent } from '@trader/types';

import { ControlInfo } from '../controlInfo';
import * as Styled from './styled';

export interface IDateTimePickerProps {
  value?: Date | null;
  minValue?: Date | null;
  maxValue?: Date | null;
  helperText?: string;
  onChange?: (event: IChangeFieldEvent<Date | null>) => void;
  isError?: boolean;
  label?: string | ReactNode;
  name?: string;
  shouldHideControlInfo?: boolean;
}

export const DateTimePicker = ({
  value,
  minValue,
  maxValue,
  onChange,
  helperText,
  label,
  isError,
  shouldHideControlInfo,
  name,
}: IDateTimePickerProps) => {
  const theme = useTheme();

  return (
    <Fragment>
      <MuiDateTimePicker
        className='date-time-picker'
        format='DD/MM/YYYY HH:mm'
        label={label}
        value={value ? dayjs(value) : undefined}
        minDateTime={minValue ? dayjs(minValue) : undefined}
        maxDateTime={maxValue ? dayjs(maxValue) : undefined}
        ampm={false}
        slots={{
          openPickerIcon: () => <Styled.CalendarIcon iconType='calendar' />,
        }}
        slotProps={{
          textField: {
            sx: {
              '& .MuiInputBase-input': {
                fontSize: theme.typography.medium.fontSize,
                padding: '8px',
              },
              '& .MuiInputLabel-root': {
                fontSize: theme.typography.large.fontSize,
                transform: 'translate(12px, 7px) scale(1)',

                '&.MuiInputLabel-shrink': {
                  transform: 'translate(15px, -10px) scale(0.85)',
                },
              },
            },
          },
          day: {
            sx: {
              fontSize: theme.typography.small.fontSize,

              '&.Mui-selected': {
                backgroundColor: `${theme.palette.primary.main}!important`,
              },
            },
          },
          desktopPaper: {
            sx: {
              width: '480px',
              height: '385px',

              '.MuiPickersCalendarHeader-labelContainer': {
                fontSize: theme.typography.large.fontSize,
              },

              '.MuiPickersYear-root': {
                height: '35px',

                button: {
                  fontSize: theme.typography.default.fontSize,
                },

                '.Mui-selected': {
                  backgroundColor: `${theme.palette.primary.main}!important`,
                },
              },

              '.MuiDayCalendar-weekDayLabel': {
                fontSize: theme.typography.small.fontSize,
              },
            },
          },
        }}
        onChange={v =>
          onChange &&
          onChange({
            target: {
              name: name || '',
              value: v ? (v as unknown as Dayjs).toDate() : null,
            },
          })
        }
      />
      <ControlInfo
        isError={isError}
        helperText={helperText}
        isHide={shouldHideControlInfo}
      />
    </Fragment>
  );
};

import { useMemo } from 'react';
import { observer } from 'mobx-react-lite';

import { Subcategories } from '@trader/components';
import { useMst } from '@trader/store';
import { useI18next } from '@trader/services';

import { InstrumentsTable } from './components/instrumentsTable';
import * as Styled from './mobile.styled';

export const MobileLeftBar = observer(() => {
  const { translate } = useI18next();
  const store = useMst();
  const category = store.pages.trading.selectedCategory;
  const subCategory = store.pages.trading.selectedSubCategory;
  const instruments = store.pages.trading.getInstrumentsByCategory(
    category?.name || '',
    subCategory
  );

  const subCategoryName = useMemo(
    () =>
      category?.subCategories.find(s => s.id.toString() === subCategory)?.name,
    [subCategory, category]
  );

  return (
    <Styled.Root>
      <Styled.TotalCount>
        <Styled.Name>{category?.name} -</Styled.Name>
        <Styled.Count>
          {subCategoryName?.toUpperCase()}{' '}
          {store.pages.trading.instrumentsAmount}&nbsp;
          {instruments.length === 1
            ? translate('COMMON.LABELS.RESULT')
            : translate('COMMON.LABELS.RESULTS')}
        </Styled.Count>
      </Styled.TotalCount>
      <Subcategories />
      <InstrumentsTable />
    </Styled.Root>
  );
});

import React from 'react';

import { Wrapper, Typography } from '@trader/components';
import { defaultIcon, iconSizes } from '@trader/constants';

interface ISymbolWithIcon {
  iconUrl?: string | null;
  iconSize?: number;
  textVariant?: 'tiny' | 'medium';
  symbol: string;
}

export const SymbolWithIcon: React.FC<ISymbolWithIcon> = ({
  iconUrl,
  iconSize = iconSizes.extraSmall,
  textVariant = 'tiny',
  symbol,
}) => {
  return (
    <Wrapper alignItems='center'>
      <img
        src={iconUrl || defaultIcon}
        alt='instrument icon'
        width={iconSize}
        height={iconSize}
      />
      <Typography variant={textVariant} fontWeight='medium' marginLeft='5px'>
        {symbol}
      </Typography>
    </Wrapper>
  );
};

import { createThunk } from '../utils/asyncModel';
import { IAuthStore } from './index';

import {
  getSystemTheme,
  getTimeInSeconds,
  returnTypedThis,
} from '@trader/utils';
import {
  devLoggerService,
  localStorageService,
  SmartLookService,
} from '@trader/services';
import { LOCAL_STORAGE_KEYS } from '@trader/constants';
import {
  api,
  IChangePasswordParams,
  IGetAuthRefreshTokenBE,
  IGetAuthTokenBE,
  IGetAuthTokenParams,
  ISignUpBody,
  ISsoParams,
} from '@trader/api';
import { applySnapshot } from 'mobx-state-tree';
import i18next from 'i18next';
import { getRootInstance } from '../configureStore/configureStore';
import { EDesignType, ETradingView } from '@trader/types';

function setAuthorizedSessionTimestamp(that: IAuthStore) {
  const timestamp = getTimeInSeconds();
  localStorageService.set(
    LOCAL_STORAGE_KEYS.tokens.authorizedSessionTimestamp,
    timestamp.toString()
  );
  that.tokens.setTokens({ authorizedSessionTimestamp: timestamp });
}

function setAuthTokens(resp: IGetAuthTokenBE, that: IAuthStore) {
  const root = getRootInstance();
  const currentAppVersion = import.meta.env.VITE_APP_VERSION;
  const congitoId = localStorageService.get(
    LOCAL_STORAGE_KEYS.tokens.cognitoId
  );
  if (resp?.cognitoId !== congitoId) {
    localStorageService.remove(LOCAL_STORAGE_KEYS.persist);
    applySnapshot(root, {
      app: {
        themeMode: getSystemTheme(),
        chartType: ETradingView.Advanced,
        designType: EDesignType.STABLE,
      },
      auth: { isAuthChecked: true, isAuth: false },
      entities: {},
      pages: {},
      user: {},
      ui: {},
    });
  }

  that.tokens.setTokens(resp);

  localStorageService.set(LOCAL_STORAGE_KEYS.tokens.access, resp?.accessToken);
  localStorageService.set(LOCAL_STORAGE_KEYS.tokens.sessionId, resp?.sessionId);
  localStorageService.set(LOCAL_STORAGE_KEYS.tokens.cognitoId, resp?.cognitoId);
  localStorageService.set(LOCAL_STORAGE_KEYS.version, currentAppVersion);
  localStorageService.set(
    LOCAL_STORAGE_KEYS.tokens.refresh,
    resp?.refreshToken
  );
  localStorageService.set(LOCAL_STORAGE_KEYS.tokens.idToken, resp?.idToken);
  localStorageService.set(
    LOCAL_STORAGE_KEYS.tokens.expiresIn,
    resp?.expiresIn?.toString()
  );
  localStorageService.set(LOCAL_STORAGE_KEYS.tokens.tokenType, resp?.tokenType);

  localStorageService.remove(LOCAL_STORAGE_KEYS.leftPageTimestamp);

  setAuthorizedSessionTimestamp(that);

  that.runInAction(() => {
    that.isAuth = true;
    that.userActivity.resetSeconds();
  });
}

export const authGetTokenAsync = createThunk<
  IGetAuthTokenParams,
  IGetAuthTokenBE | undefined
>(body => {
  return async function authGetTokens(this: unknown, options, _flow) {
    const that = returnTypedThis<IAuthStore>(this);
    try {
      const resp = await api.Auth.getToken(
        {
          ...body,
          deviceToken:
            localStorageService.get(
              LOCAL_STORAGE_KEYS.tokens.firebaseMessagingToken
            ) || undefined,
        },
        options
      );
      resp && setAuthTokens(resp, that);
      return resp;
    } catch (e) {
      devLoggerService.error('catch authGetTokenAsync error', e);
    }
  };
});

export const signUpAsync = createThunk<ISignUpBody, void>(body => {
  return async function signUp(this: unknown, options, _flow) {
    await api.Auth.signUp(body, options);
    SmartLookService.track('Login');
  };
});

// #Workaround for fix TS ISSUE - type TAuthStore = any, Type alias 'TAuthStore' circularly references itself.ts(2456)
// https://www.bookstack.cn/read/typescriptlang-3.7/spilt.6.055dccf6c3f8aae3.md
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getRefreshToken = (store: any): string =>
  store.tokens.refreshToken as unknown as string;

export const authRefreshTokenAsync = createThunk<
  void,
  IGetAuthRefreshTokenBE | undefined
>(() => {
  return async function authRefreshToken(this: unknown, options, _flow) {
    const that = returnTypedThis<IAuthStore>(this);
    const refreshToken = getRefreshToken(that);
    if (!refreshToken) {
      that.logOut();
      return;
    }
    try {
      const resp = await api.Auth.refreshToken({ refreshToken }, options);

      that.tokens.setTokens(resp);
      localStorageService.set(
        LOCAL_STORAGE_KEYS.tokens.access,
        resp.accessToken
      );
      localStorageService.set(LOCAL_STORAGE_KEYS.tokens.idToken, resp.idToken);
      localStorageService.set(
        LOCAL_STORAGE_KEYS.tokens.expiresIn,
        resp.expiresIn.toString()
      );
      localStorageService.set(
        LOCAL_STORAGE_KEYS.tokens.tokenType,
        resp.tokenType
      );
      setAuthorizedSessionTimestamp(that);

      return resp;
    } catch (e) {
      devLoggerService.error('catch authRefreshTokenAsync error', e);
    }
  };
});

export const loginAsync = createThunk<IGetAuthTokenParams, void>(body => {
  return async function login(this: unknown, _options, _flow) {
    const that = returnTypedThis<IAuthStore>(this);
    await that.authGetTokenAsync.run(body);
  };
});

export const ssoAsync = createThunk<ISsoParams, { cognitoId: string }>(body => {
  return async function sso(this: unknown, _options, _flow) {
    const that = returnTypedThis<IAuthStore>(this);
    const resp = await api.Auth.sso(body, _options);

    setAuthTokens(resp, that);
    return { cognitoId: resp.cognitoId };
  };
});

export const changePasswordAsync = createThunk<IChangePasswordParams, void>(
  body => {
    return async function changePassword(this: unknown, _options, _flow) {
      const root = getRootInstance();

      await api.Auth.changePassword(body, _options);
      root.notifications.add({
        message: i18next.t('NOTIFICATIONS.PASSWORD_HAS_BEEN_CHANGED'),
        options: {
          variant: 'success',
        },
      });
    };
  }
);

import React from 'react';
import { useMediaQuery, useTheme } from '@mui/material';

import { DesktopLeftBar } from './desktop';
import { MobileLeftBar } from './mobile';

export const LeftBar: React.FC = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return isMobile ? <MobileLeftBar /> : <DesktopLeftBar />;
};

import React, { useMemo } from 'react';
import { observer } from 'mobx-react-lite';

import {
  IHeadCell,
  IRenderRowItemsCallBacks,
  PurchaseType,
  ScrollingList,
} from '@trader/components';
import {
  EOrderCreatedBy,
  EOrderSide,
  EPositionClosedBy,
  EStripName,
  IBackTestingPosition,
} from '@trader/types';
import {
  formatDate,
  fixToTwoDigitAfterDot,
  formatTableRowValueByPipSize,
  groupBiaxialChartDataByCloseTime,
} from '@trader/utils';
import { TInstrumentEntity, useMst } from '@trader/store';
import { useI18next } from '@trader/services';

import { BackTestHeader } from '../header';
import { ProfitSummaryChart } from '../performanceSummary/profitSummaryChart';
import { PerformanceSummaryCard } from '../performanceSummary/performanceSummaryCard';
import * as Styled from './styled';

export const ListOfTrades: React.FC = observer(() => {
  const store = useMst();
  const { translate } = useI18next();

  const backTesting = store.pages.muliBands.backTesting;
  const multiplier = backTesting.getMultiplier();
  const listOfTrades = backTesting.getListOfTrades();
  const symbol = backTesting.getSymbol();
  const instrument = store.entities.instruments.get<TInstrumentEntity>(symbol);

  const cells: Array<IHeadCell> = [
    {
      id: 'side',
      label: translate('COMMON.LABELS.SIDE'),
      minWidth: 40,
      align: 'start',
    },
    {
      id: 'multiplier',
      label: translate('MULI_BANDS.MULTIPLIER'),
      minWidth: 60,
      align: 'center',
      sortable: true,
    },
    {
      id: 'bandId',
      label: translate('MULI_BANDS.BAND'),
      minWidth: 100,
      align: 'center',
      sortable: true,
    },
    {
      id: 'closedBy',
      label: translate('MULI_BANDS.CLOSED_BY'),
      minWidth: 100,
      align: 'center',
    },
    {
      id: 'orderCreatedBy',
      label: translate('MULI_BANDS.CREATED_BY'),
      minWidth: 100,
      align: 'center',
    },
    {
      id: 'profit',
      label: translate('COMMON.LABELS.PROFIT'),
      minWidth: 60,
      align: 'center',
      sortable: true,
    },
    {
      id: 'openPrice',
      label: translate('COMMON.LABELS.OPEN_PRICE'),
      minWidth: 85,
      align: 'center',
    },
    {
      id: 'closePrice',
      label: translate('COMMON.LABELS.CLOSE_PRICE'),
      minWidth: 85,
      align: 'center',
    },
    {
      id: 'openTime',
      label: translate('COMMON.LABELS.OPEN_TIME'),
      minWidth: 100,
      align: 'center',
      sortable: true,
      sortType: 'date',
    },
    {
      id: 'closeTime',
      label: translate('COMMON.LABELS.CLOSE_TIME'),
      minWidth: 100,
      align: 'center',
      sortable: true,
      sortType: 'date',
    },
  ];

  const renderRowItemsCb: IRenderRowItemsCallBacks<IBackTestingPosition> =
    useMemo(
      () => ({
        profit: item => fixToTwoDigitAfterDot(item.value as number),
        side: item => (
          <PurchaseType
            value={(item.value as EOrderSide) === 0 ? 'Buy' : 'Sell'}
            variant='fit-content'
          />
        ),
        multiplier: item => item.value,
        bandId: item => EStripName[item.value as EStripName],
        closedBy: item => EPositionClosedBy[item.value as EPositionClosedBy],
        orderCreatedBy: item => EOrderCreatedBy[item.value as EOrderCreatedBy],
        openPrice: item =>
          formatTableRowValueByPipSize(item.value, instrument.pipSize),
        closePrice: item =>
          formatTableRowValueByPipSize(item.value, instrument.pipSize),
        openTime: item =>
          formatDate(new Date(item.value as string), 'Mm dd, yyyy hh:mm:ss'),
        closeTime: item =>
          formatDate(new Date(item.value as string), 'Mm dd, yyyy hh:mm:ss'),
      }),
      []
    );

  const getCsvData = () => {
    const allPositions = backTesting
      .getAllMultipliers()
      .map(m => m.positions)
      .flat();

    return {
      data: {
        headers: cells.map(cell => ({
          label: cell.label as string,
          id: cell.id,
        })),
        rows: allPositions.map(position => ({
          ...position,
          side: EOrderSide[position.side],
          bandId: EStripName[position.bandId],
          openTime: formatDate(
            new Date(position.openTime),
            'Mm dd, yyyy hh:mm:ss'
          ),
          closedBy: EPositionClosedBy[position.closedBy],
          orderCreatedBy: EOrderCreatedBy[position.orderCreatedBy],
          closeTime: formatDate(
            new Date(position.closeTime),
            'Mm dd, yyyy hh:mm:ss'
          ),
        })),
      },
      fileName: `List of trades ${formatDate(
        new Date(),
        'Mm dd, yyyy hh:mm:ss',
        { shouldUseUTC: false }
      )} `,
    };
  };

  return (
    <>
      <BackTestHeader onGetCsvData={getCsvData} />
      {multiplier && (
        <ProfitSummaryChart
          chartLegend={[
            {
              label: translate('COMMON.LABELS.TOTAL'),
              value: multiplier.totalProfit,
              type: 'total',
            },
            {
              label: translate('COMMON.LABELS.MAX'),
              value: multiplier.maxProfit,
              type: 'max',
            },
          ]}
          chartData={
            Object.values(
              groupBiaxialChartDataByCloseTime(listOfTrades)
            ).flat() as { max: number; total: number; name: string }[]
          }
        />
      )}
      {!!listOfTrades.length && (
        <Styled.Summaries>
          <PerformanceSummaryCard
            type='days'
            emptyMessage={
              !multiplier
                ? translate('MULI_BANDS.BAND_NOT_SELECTED')
                : undefined
            }
            items={[
              {
                label: translate('COMMON.LABELS.PROFIT'),
                value: multiplier?.profitDays,
              },
              {
                label: translate('COMMON.LABELS.LOSS'),
                value: multiplier?.lossDays,
              },
              {
                label: translate('MULI_BANDS.WO_HIT'),
                value: multiplier?.daysWoHit,
              },
            ]}
          />
          <PerformanceSummaryCard
            type='vwap'
            emptyMessage={
              !multiplier
                ? translate('MULI_BANDS.BAND_NOT_SELECTED')
                : undefined
            }
            items={[
              {
                label: translate('MULI_BANDS.RETURNED'),
                value: multiplier?.returnedToVwapDays,
              },
              {
                label: translate('MULI_BANDS.NO_RETURNED'),
                value: multiplier?.notReturnedToVwapDays,
              },
              {
                label: translate('MULI_BANDS.RETURNED_WITH_LOSS'),
                value: multiplier?.returnedToVwapWithLoss,
              },
            ]}
          />
          <PerformanceSummaryCard
            type='consecutiveDays'
            emptyMessage={
              !multiplier
                ? translate('MULI_BANDS.BAND_NOT_SELECTED')
                : undefined
            }
            items={[
              {
                label: translate('COMMON.LABELS.WIN'),
                value: multiplier?.maxConsecutiveWinDays,
              },
              {
                label: translate('COMMON.LABELS.LOSS'),
                value: multiplier?.maxConsecutiveLossDays,
              },
              {
                label: translate('COMMON.LABELS.SL'),
                value: multiplier?.maxConsecutiveSlDays,
              },
            ]}
          />
          <PerformanceSummaryCard
            type='lowerBand'
            emptyMessage={
              !multiplier
                ? translate('MULI_BANDS.BAND_NOT_SELECTED')
                : undefined
            }
            items={[
              {
                label: translate('MULI_BANDS.RETURNED_WITH_LOSS'),
                value: multiplier?.returnedToLowerBandWithLoss,
              },
            ]}
          />
        </Styled.Summaries>
      )}
      <ScrollingList<IBackTestingPosition>
        rows={listOfTrades}
        headCells={cells}
        renderRowItemsCallBacks={renderRowItemsCb}
        emptyMessage={
          !backTesting.selectedMultiplierOption
            ? translate('MULI_BANDS.MULTIPLIER_NOT_SELECTED')
            : undefined
        }
      />
    </>
  );
});

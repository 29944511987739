import { fixTotalValue } from '@trader/utils';

const hundred = 100;

export const calcEquity = (allPl: number, balance: number, credit: number) => {
  return allPl + balance + credit;
};

export const calcFreeMargin = (equity: number, usedMargin: number) => {
  return equity - usedMargin;
};

export const calcMarginLevel = (equity: number, usedMargin: number) => {
  return (equity / usedMargin) * hundred;
};

export const calcAsset = (currentPrice: number, quantity: number) => {
  return currentPrice * quantity;
};

export const formatAccountCurrencyValue = (
  value: number | null | undefined,
  currencySymbol: string
) => {
  if (!value || !Number.isFinite(value)) {
    return '--';
  }
  return `${currencySymbol}${value.toFixed(fixTotalValue)}`;
};

export const formatAccountPercentageValue = (
  value: number | null | undefined
) => {
  if (!value || !Number.isFinite(value)) {
    return '--';
  }

  return `${value.toFixed(fixTotalValue)}%`;
};

import { FC } from 'react';
import { observer } from 'mobx-react-lite';

import { TAlertEntity } from '@trader/store';
import { formatTableRowValueByPipSize } from '@trader/utils';

import * as Styled from '../../desktop.styled';

interface IAlertCurrentPrice {
  alert: TAlertEntity;
}

export const AlertCurrentPrice: FC<IAlertCurrentPrice> = observer(
  ({ alert }) => {
    const instrument = alert.conditions[0].instrument;

    return (
      <Styled.Title>
        {instrument?.currencySymbol}
        {formatTableRowValueByPipSize(
          instrument?.currentPrice,
          instrument?.pipSize
        )}
      </Styled.Title>
    );
  }
);
